import React from 'react';

function Icon(props: any) {
  const { size = 64, color = '#F10C0C', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M31.997 8c-13.254 0-24 10.744-24 24 0 13.254 10.746 24 24 24 13.255 0 24-10.746 24-24 0-3.182-.617-6.212-1.736-8.983a2.667 2.667 0 114.945-1.997A29.258 29.258 0 0161.331 32c0 16.2-13.133 29.333-29.334 29.333-16.2 0-29.333-13.133-29.333-29.334 0-16.2 13.133-29.333 29.333-29.333 2.794 0 5.501.391 8.067 1.124A2.667 2.667 0 1138.6 8.918 24.017 24.017 0 0031.997 8zm12.797.446a2.667 2.667 0 013.705-.701 29.482 29.482 0 015.79 5.187 2.667 2.667 0 11-4.052 3.468 24.149 24.149 0 00-4.741-4.248 2.667 2.667 0 01-.702-3.706z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M43.552 20.781a2.667 2.667 0 010 3.771l-19 19a2.667 2.667 0 01-3.771-3.771l19-19a2.667 2.667 0 013.771 0z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M20.784 20.781a2.667 2.667 0 000 3.771l19 19a2.667 2.667 0 103.77-3.771l-19-19a2.667 2.667 0 00-3.77 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
