import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { MutationFetchFunction } from 'common/helpers/common';
import { ApiError, ApiResult } from 'common/repositories/common.model';

export function useReadNotification(
  options?: UseMutationOptions<ApiResult<any>, ApiError>,
) {
  return useMutation<ApiResult<any>, ApiError>(async function () {
    return await MutationFetchFunction({
      url: 'notifications/read',
      method: 'PATCH',
    });
  }, options);
}
