import React from 'react';

function Icon(props: any) {
  const { size = 24, color = '#8F9596', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size + 1}
      height={size}
      fill="none"
      viewBox="0 0 25 24"
      {...rest}
    >
      <g clipPath="url(#clip0_2313_32140)">
        <path
          fill={color}
          d="M7.1 13.188c1.654 0 3-1.333 3-2.97 0-1.636-1.346-2.968-3-2.968s-3 1.332-3 2.969c0 1.636 1.346 2.969 3 2.969zM20.9 7.25h-9c-.33 0-.6.267-.6.594v6.531H2.9V4.281a.597.597 0 00-.6-.593H1.1c-.331 0-.6.265-.6.593V19.72c0 .326.269.593.6.593h1.2c.332 0 .6-.267.6-.593v-1.782h19.2v1.782c0 .328.269.593.6.593h1.2c.331 0 .6-.265.6-.593v-8.907c0-2-1.613-3.562-3.6-3.562z"
        />
        <path
          fill={color}
          d="M19.817 2.456a2 2 0 012.913 2.741L5.789 23.2a2 2 0 11-2.913-2.741l16.94-18.002z"
        />
        <path
          fill="#F8F9F9"
          fillRule="evenodd"
          d="M5.06 22.514L22.001 4.512a1 1 0 00-1.456-1.37L3.604 21.143a1 1 0 001.456 1.371zM22.644 2.371a2 2 0 00-2.827.085L2.876 20.458A2 2 0 105.789 23.2l16.94-18.001a2 2 0 00-.085-2.827z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_2313_32140">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
