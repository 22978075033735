import { css, styled } from 'styles';
import typography from 'styles/typography';

export const CardContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$white',
  borderRadius: 12,
  maxWidth: 674,
  width: '100%',
  px: 16,
  py: 32,
  '@md': {
    px: 48,
    py: 48,
  },
});

export const ALinkMenu = styled('a', {
  ...typography.body2,
  fontWeight: '$bold',
  color: '$primary',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid $white',
  padding: 4,
  '&:hover': {
    borderBottom: '1px solid $primary',
  },
  '&:focus': {
    outlineColor: '#DCD4FF',
    outlineStyle: 'solid',
    outlineWidth: 'medium',
  },
});

export const styles = {
  mainClass: css({
    backgroundImage: 'url("/assets/auth-bg.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    // height: '100%',
    alignItems: 'center',
    backgroundPosition: 'center',
    py: 64,
    px: 16,
  }),
  inputCss: { width: 'auto', '@md': { width: '70%', mx: 'auto' } },
};
export const ALinkTerm = styled('a', {
  ...typography.body4,
  fontWeight: '600',
  color: '$primary3',
  textDecoration: 'none',
  '&:hover': {
    color: '$primary2',
  },
});
