import { QueryClient } from '@tanstack/react-query';
import { plainToClass } from 'class-transformer';

import { ExtendedApiResult, PaginationMeta } from './common.model';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      getNextPageParam: (lastPage: ExtendedApiResult<any[]>) => {
        const newPage = {
          ...lastPage,
          meta: plainToClass(PaginationMeta, lastPage.meta),
        };
        return newPage.meta.currentPage !== newPage.meta.lastPage
          ? newPage.meta.currentPage + 1
          : undefined;
      },
    },
    mutations: {
      retry: false,
    },
  },
});
