import { useRefreshToken } from 'api-hooks/auth/mutation';
import {
  clearCredential,
  getRefreshToken,
  isAccessTokenExpired,
  setCredential,
} from 'common/credential-storage';
import { queryClient } from 'common/repositories/query-client';
import React from 'react';

export default function RefreshTokenHandler() {
  const [initialRender, setInitialRender] = React.useState(false);
  const { mutateAsync, isLoading } = useRefreshToken();

  const onRefreshToken = React.useCallback(async () => {
    if (isLoading) return;
    const refreshToken = getRefreshToken();
    if (!refreshToken) return;
    const result = await mutateAsync({ refreshToken });
    setCredential(result.data);
  }, [isLoading, mutateAsync]);

  React.useEffect(() => {
    const interval = setInterval(async () => {
      const isTokenExpired = isAccessTokenExpired();
      if (!isTokenExpired) return;
      try {
        await onRefreshToken();
      } catch (e) {
        clearCredential();
      } finally {
        queryClient.clear();
        queryClient.invalidateQueries();
      }
    }, 1000);

    if (!initialRender) {
      onRefreshToken();
      setInitialRender(true);
    }

    return () => clearInterval(interval);
  }, [initialRender, onRefreshToken]);

  return null;
}
