import React from 'react';

function Icon(props: any) {
  const { size = 24, color = '#104FA6', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        fill={color}
        d="M17.6 35.165c4.41 0 8-3.552 8-7.916 0-4.364-3.59-7.917-8-7.917s-8 3.553-8 7.917 3.59 7.916 8 7.916zm36.8-15.833h-24c-.88 0-1.6.712-1.6 1.583v17.417H6.4V11.415c0-.874-.716-1.583-1.6-1.583H1.6c-.884 0-1.6.709-1.6 1.583v41.167c0 .87.716 1.583 1.6 1.583h3.2c.884 0 1.6-.712 1.6-1.583v-4.75h51.2v4.75c0 .874.716 1.583 1.6 1.583h3.2c.884 0 1.6-.709 1.6-1.583v-23.75c0-5.334-4.3-9.5-9.6-9.5z"
      />
    </svg>
  );
}

export default Icon;
