import React from 'react';

function Icon(props: any) {
  const { size = 111, color = '#327FEB', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size - 1}
      fill="none"
      viewBox="0 0 111 110"
      {...rest}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M96.308 47.994a5.376 5.376 0 00-5.235-4.15H27.197a5.376 5.376 0 00-5.376 5.376v14.556h4.135V49.22c0-.685.556-1.24 1.241-1.24h63.876a1.24 1.24 0 011.208.957l3.263 13.936 4.026-.943-3.262-13.936z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M37.422 28.376A5.376 5.376 0 0032.046 23h-.4a5.376 5.376 0 00-5.376 5.376v19.601h11.152V28.376zm-5.376-1.24c.685 0 1.24.555 1.24 1.24v15.466h-2.88V28.376c0-.685.555-1.24 1.24-1.24h.4zM51.574 28.368A5.376 5.376 0 0046.198 23h-.4a5.376 5.376 0 00-5.376 5.384l.028 19.593h11.152l-.028-19.609zm-5.376-1.233c.684 0 1.24.555 1.24 1.24l.022 15.467h-2.88l-.023-15.464a1.24 1.24 0 011.241-1.243h.4zM83.747 35.413a5.376 5.376 0 00-5.171-3.905h-18.75a5.376 5.376 0 00-5.375 5.376v11.091h32.87l-3.574-12.562zm-5.171.23a1.24 1.24 0 011.193.901l2.076 7.296H58.586v-6.956c0-.685.555-1.24 1.24-1.24h18.75zM107.636 67.448c1.604-3.557-.998-7.585-4.901-7.585H75.192c-1.61 0-3.134.721-4.155 1.965l-2.273 2.768a1.24 1.24 0 01-.96.453H39.64a1.24 1.24 0 01-1.028-.546l-1.532-2.27a5.376 5.376 0 00-4.456-2.37H20.762c-3.435 0-5.989 3.177-5.25 6.531l3.793 17.248 4.038-.888-3.793-17.248a1.24 1.24 0 011.212-1.507h11.86c.413 0 .799.205 1.03.546l1.53 2.27 1.699-1.145-1.698 1.145c.999 1.482 2.67 2.37 4.456 2.37h28.166c1.609 0 3.133-.72 4.155-1.965l2.273-2.768a1.24 1.24 0 01.959-.453h27.543c.901 0 1.501.929 1.131 1.75l-5.885 13.056 3.77 1.7 5.885-13.057z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M32.161 83.6a16.176 16.176 0 0111.77-.266l1.342.49a23.966 23.966 0 0016.429 0l.531-.195a19.831 19.831 0 0113.595 0l1.902.695a18.783 18.783 0 0013.667-.31 14.647 14.647 0 0112.884.79l2.831 1.612a2.068 2.068 0 002.046-3.593l-2.831-1.612a18.782 18.782 0 00-16.522-1.013 14.648 14.648 0 01-10.658.24l-1.902-.693a23.965 23.965 0 00-16.43 0l-.53.194a19.831 19.831 0 01-13.595 0l-1.341-.49a20.312 20.312 0 00-14.78.334l-1.074.448a13.385 13.385 0 01-10.303 0 17.52 17.52 0 00-16.49 1.611l-1.581 1.06a2.068 2.068 0 002.3 3.436l1.582-1.06a13.385 13.385 0 0112.598-1.23c4.315 1.8 9.17 1.8 13.486 0l1.074-.448z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
