import React from 'react';

function Icon(props: any) {
  const { size = 32, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 32 32"
      {...rest}
    >
      <rect width="32" height="32" fill="#BED6F9" fillOpacity="0.2" rx="16" />
      <path
        fill="#104FA6"
        fillRule="evenodd"
        d="M11.334 11.333a.667.667 0 00-.667.667v9.333c0 .369.299.667.667.667h9.333a.667.667 0 00.667-.667V12a.667.667 0 00-.667-.667h-9.333zm-2 .667a2 2 0 012-2h9.333a2 2 0 012 2v9.333a2 2 0 01-2 2h-9.333a2 2 0 01-2-2V12z"
        clipRule="evenodd"
      />
      <path
        fill="#104FA6"
        fillRule="evenodd"
        d="M18.667 8.666c.368 0 .666.298.666.667v2.666A.667.667 0 1118 12V9.333c0-.369.299-.667.667-.667zM13.333 8.666c.368 0 .666.298.666.667v2.666a.667.667 0 11-1.333 0V9.333c0-.369.298-.667.667-.667zM9.334 14.667c0-.368.298-.667.667-.667h12a.667.667 0 010 1.333H10a.667.667 0 01-.667-.666z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
