import React from 'react';

function Icon(props: any) {
  const { size = 24, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 512 512"
      {...rest}
    >
      <g clipPath="url(#clip0_1962_32483)">
        <path
          fill="#41479B"
          d="M473.655.008H38.345C17.167.008 0 26.21 0 58.534v394.942c0 32.322 17.167 58.526 38.345 58.526h435.31c21.177 0 38.345-26.202 38.345-58.526V58.534C512 26.21 494.833.008 473.655.008z"
        />
        <path
          fill="#F5F5F5"
          d="M511.469 48.852C508.447 21.136 492.672 0 473.655 0h-9.977l-163.54 163.538V.002h-88.276v163.537L48.322.002h-9.977C19.328.002 3.553 21.136.531 48.854l139.778 139.778H0v134.735h140.309L.531 463.142c3.022 27.716 18.797 48.852 37.814 48.852h9.977l163.54-163.538v163.538h88.276V348.456l163.54 163.538h9.977c19.017 0 34.792-21.135 37.814-48.852L371.691 323.364H512V188.629H371.691L511.469 48.852z"
        />
        <path
          fill="#FF4B55"
          d="M282.483.008h-52.966v215.575H0v80.842h229.517V512h52.966V296.425H512v-80.842H282.483V.008z"
        />
        <path
          fill="#FF4B55"
          d="M24.796 508.227l186.583-184.856h-32.428L9.227 491.526c4.153 7.398 9.49 13.195 15.569 16.701zM346.389 323.371h-32.428l180.716 179.042c5.057-5.069 9.277-11.916 12.287-19.956L346.389 323.371zM4.047 32.363l157.73 156.272h32.428L15.473 11.555c-4.8 5.452-8.726 12.58-11.426 20.808zM332.569 188.638L502.604 20.173c-4.199-7.373-9.578-13.137-15.699-16.575l-186.764 185.04h32.428z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1962_32483">
          <rect width="512" height="512" fill="#fff" rx="256" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
