import React from 'react';

function Icon(props: any) {
  const { size = 32, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 32 32"
      {...rest}
    >
      <rect width="32" height="32" fill="#BED6F9" fillOpacity="0.2" rx="16" />
      <path
        fill="#104FA6"
        fillRule="evenodd"
        d="M16 8c.369 0 .667.298.667.667v14.666a.667.667 0 11-1.333 0V8.667c0-.369.299-.667.667-.667z"
        clipRule="evenodd"
      />
      <path
        fill="#104FA6"
        fillRule="evenodd"
        d="M12.213 11.545a3 3 0 012.121-.879h5a.667.667 0 010 1.333h-5a1.666 1.666 0 100 3.334h3.333a3 3 0 010 6h-5.666a.667.667 0 010-1.334h5.666a1.666 1.666 0 100-3.333h-3.333a3 3 0 01-2.121-5.121z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
