import { Expose, Type } from 'class-transformer';
export class LocaleModel {
  en: string;
  id: string;
}
export enum NotificationTypeEnum {
  PAYMENT_SUCCESS = 'payment_success',
  PAYMENT_PENDING = 'payment_pending',
  PAYMENT_FAILED = 'payment_failed',
  REFUND_ACCEPTED = 'refund_accepted',
  REFUND_PENDING = 'refund_pending',
  REFUND_REJECTED = 'refund_rejected',
  REMINDER = 'reminder',
  DELAY = 'delay',
  TRANSFER = 'transfer',
}
export class NotificationData {
  url: string | null;
  @Type(() => LocaleModel)
  body: LocaleModel;
  type: string;
  @Type(() => LocaleModel)
  subject: LocaleModel;
}

export class NotificationModel {
  id: string;
  @Type(() => NotificationData)
  data: NotificationData;
  @Expose({ name: 'read_at' })
  readAt: Date | null;
  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;
}

export class getNotificationsInput {
  params: {
    page?: number;
  };
}
