import React from 'react';

function Icon(props: any) {
  const { size = 48, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size - 3}
      fill="none"
      viewBox="0 0 22 19"
      {...rest}
    >
      <mask
        id="mask0_4700_16622"
        style={{ maskType: 'alpha' }}
        width="13"
        height="15"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle
          cx="6.898"
          cy="3.199"
          r="2.375"
          stroke="#327FEB"
          strokeWidth="1.25"
        />
        <path
          stroke="#327FEB"
          strokeWidth="1.25"
          d="M12.238 13.574H1.559a5.376 5.376 0 0110.679 0z"
        />
      </mask>
      <g mask="url(#mask0_4700_16622)">
        <path fill="#327FEB" d="M7.689 13.854L12.07-.54H-5.452v14.393H7.689z" />
      </g>
      <mask
        id="mask1_4700_16622"
        style={{ maskType: 'alpha' }}
        width="22"
        height="15"
        x="0"
        y="4"
        maskUnits="userSpaceOnUse"
      >
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.25"
          d="M1.663 9.438l-.646 4.621c-.148 1.06.667 2.008 1.726 2.008 2.664 0-.18-.01 5.554 0h5.699c.006 0 .01-.004.011-.01.388-3.144 4.783-3.209 5.432-.234.03.138.15.244.291.244v0c.38 0 .742-.173.983-.47.201-.25.303-.566.285-.887l-.068-1.202a3.112 3.112 0 00-.714-1.816 3.057 3.057 0 00-1.436-.962l-2.383-.746-.332-.685a6.851 6.851 0 00-5.453-3.838H5.167c-.223 0-.442.062-.633.179l-.142.087a6.126 6.126 0 00-2.73 3.711z"
        />
        <path
          stroke="#000"
          strokeWidth="1.25"
          d="M17.93 16.38c0 .694-.556 1.246-1.227 1.246a1.237 1.237 0 01-1.226-1.247c0-.694.555-1.246 1.226-1.246.671 0 1.227.552 1.227 1.247zM6.82 16.38c0 .694-.555 1.246-1.226 1.246a1.237 1.237 0 01-1.227-1.247c0-.694.556-1.246 1.227-1.246.67 0 1.226.552 1.226 1.247z"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.25"
          d="M8.68 10.295V7.02h1.176c.647 0 1.286.147 1.867.43a5.598 5.598 0 012.193 1.912l.627.933H8.68zM7.445 10.295V7.02h-.632a2.91 2.91 0 00-1.647.51c-.553.381-.997.9-1.287 1.507l-.6 1.258h4.166z"
        />
      </mask>
      <g mask="url(#mask1_4700_16622)">
        <path fill="#327FEB" d="M7.64 24.308l7.5-20.335h15.323v20.335H7.641z" />
      </g>
      <path stroke="#327FEB" d="M7.39 17.91l4.746-13.285" />
    </svg>
  );
}

export default Icon;
