import {
  plainToClass,
  ClassConstructor,
  plainToInstance,
} from 'class-transformer';
import { client } from 'common/repositories';
import { PaginationMeta, Filter, Sort } from 'common/repositories/common.model';
import toApiError from 'common/repositories/to-api-error';
import { decamelizeKeys } from 'humps';
import qs from 'qs';

type MutationMethodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface FetchMutationOptions<T> {
  url: string;
  method: MutationMethodType;
  body?: any;
  classType?: ClassConstructor<T>;
}

export async function blobToBase64(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function () {
      reject(reader.error);
    };
  });
}

export function MutationFetchFunction<T>({
  url,
  method,
  body,
  classType,
}: FetchMutationOptions<T>): Promise<any> {
  return new Promise(async (resolve, reject) => {
    const newBody = body ? decamelizeKeys(body) : undefined;

    try {
      const json = (await client(url, {
        method,
        ...(newBody
          ? {
              json: newBody,
            }
          : {}),
      })) as any;

      const contentType = json.headers.get('content-type');

      let newJson = json;

      if (contentType !== 'application/pdf') {
        try {
          newJson = await json.json();
        } catch {
          newJson = json;
        }
      } else {
        newJson = await blobToBase64(await json.blob());
      }

      const transformedJson = {
        ...newJson,
        ...(newJson?.data
          ? {
              data: classType
                ? plainToClass(classType, newJson.data)
                : newJson.data,
            }
          : {}),
      };

      resolve(contentType === 'application/pdf' ? newJson : transformedJson);
    } catch (e) {
      reject(await toApiError(e as Error));
    }
  });
}

interface QueryFetchOptions {
  url: string;
  params?: any;
}

export function QueryFetchFunction({
  url,
  params,
}: QueryFetchOptions): Promise<any> {
  return new Promise(async (resolve, reject) => {
    let _params = '';
    _params = params ? qs.stringify(params) : '';
    try {
      const json: any = await client
        .get(url, {
          ...(_params
            ? {
                searchParams: _params,
              }
            : {}),
        })
        .json();

      resolve(json);
    } catch (e: any) {
      reject(await toApiError(e));
    }
  });
}

function __QueryTransformData(res, dataType) {
  return {
    ...res,
    data: dataType ? plainToInstance(dataType, res.data) : res.data,
    ...(res.meta ? { meta: plainToInstance(PaginationMeta, res.meta) } : {}),
    ...(res.sorts
      ? {
          sorts: plainToInstance(Sort, res.sorts),
        }
      : {}),
    ...(res.filters
      ? {
          filters: plainToInstance(Filter, res.filters),
        }
      : {}),
  };
}

export function QueryTransformer(res: any, dataType?: any) {
  const { data: json } = res;

  if (json === undefined) {
    return res;
  }
  let newJson: any;
  if (json.pages) {
    newJson = {
      ...json,
      ...(json.pages
        ? {
            pages:
              dataType && !!json?.pages.length
                ? json.pages.map((page) => {
                    return __QueryTransformData(page, dataType);
                  })
                : json.pages,
          }
        : {}),
    };
  } else {
    newJson = __QueryTransformData(json, dataType);
  }

  return {
    ...res,
    data: newJson,
  };
}

export function getCookies() {
  const pairs = document.cookie.split(';');
  const cookies = {};
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='));
  }
  return cookies;
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function get_cookie(name) {
  return document.cookie.split(';').some((c) => {
    return c.trim().startsWith(name + '=');
  });
}

export function deleteCookie(name, path?: any, domain?: any) {
  if (get_cookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
}
