const typography = {
  headline1: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 56,
    lineHeight: '120%',
  },
  headline2: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 48,
    lineHeight: '120%',
  },
  headline3: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 32,
    lineHeight: '120%',
  },
  headline4: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '120%',
  },
  headline5: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '120%',
  },
  headline6: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '120%',
  },
  headline7: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '120%',
  },

  body1: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '140%',
  },
  body1Semibold: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '140%',
  },
  body2: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '140%',
  },
  body2Semibold: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '140%',
  },

  body3: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '140%',
  },
  body3Semibold: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '140%',
  },
  body4: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '140%',
  },
  body4Semibold: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '140%',
  },
  body5: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '120%',
  },
  body5Semibold: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '120%',
  },
  body6: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '120%',
  },
  body6Semibold: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '120%',
  },

  buttonLarge: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '24px',
  },
  buttonNormal: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
  },
  buttonMedium: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
  },
  buttonSmall: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '16px',
  },
  buttonExtraSmall: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
  },
};

export default typography;
