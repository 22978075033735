import { styled } from 'styles';

export const BottomSheetContainer = styled('div', {
  borderRadius: '16px 16px 0px 0px',
  backgroundColor: '$primary2',
  color: '$white',
  position: 'fixed',
  zIndex: 10,
  bottom: 0,
  left: 0,
  right: 0,
  gap: 8,
  margin: 'auto',
});

export const BottomSheetContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',
  gap: 8,
  py: 16,
  width: '100%',
  px: 16,
  '@md': {
    maxWidth: 768,
    flexDirection: 'row',
    py: 16,
  },
  '@lg': {
    maxWidth: 992,
  },
  '@xl': {
    maxWidth: 1200,
  },
});
