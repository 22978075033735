const color = {
  // primary
  primary: '#104FA6',
  primary2: '#1565D6',
  primary3: '#327FEB',
  primary4: '#619CF0',
  primary5: '#8FB9F4',
  primary6: '#BED6F9',

  // secondary
  secondary: '#F6F7F8',
  secondary2: '#F8F9F9',

  // state
  disabled: '#8F9596',
  disabledBg: '#EAEAEB',
  error: '#F10C0C',
  errorBg: '#FEECEC',
  info: '#1890FF',
  infoBg: '#ECF6FF',
  success: '#00C800',
  successBg: '#EBFBEB',
  warning: '#654B08',
  warningBg: '#FDFBED',

  // overlay
  disabledOverlay: '#8F959614',
  errorOverlay: '#f10c0c14',
  infoOverlay: '#1890FF14',
  successOverlay: '#00c80014',
  warningOverlay: '#efd44d14',
  overlayDark: '#4f555833',
  overlayLight: '#ffffff80',

  // neutral
  neutral1: '#F3F3F4',
  neutral2: '#C9CBCF',
  neutral3: '#AEB1B7',
  neutral4: '#93979F',
  neutral5: '#787D87',
  neutral6: '#60646C',
  neutral7: '#484B51',
  neutral8: '#303236',

  // divider & border
  dbGray1: '#D8DBDF',
  dbGray2: '#86898A',
  dbBlack: '#303132',

  // type
  typeBlack: '#171C22',
  typeGray: '#6F797B',
  typeLightGrey: '#C9CCCF',
  typeDisabled: '#8F9596',
  typeDisabled2: '#E2E4E8',

  white: '#FFFFFF',
};

export default color;
