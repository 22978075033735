import { NoCookieSvg } from 'assets/images/svg';
import DialogBackdrop from 'components/commons/dialog/dialog-backdrop';
import Stack from 'components/commons/stack';
import Text from 'components/commons/text';
import Button from 'components/elements/button';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import typography from 'styles/typography';
interface Props {
  onClose: () => void;
  onConfirm: () => void;
}
export default function CookiesDialog(props: Props) {
  const { onClose, onConfirm } = props;
  const { t } = useTranslation();
  return (
    <DialogBackdrop cancelable={false} onClose={onClose}>
      <Stack
        alignItems="center"
        rootCss={{
          background: '$white',
          borderRadius: 8,
          width: 'fit-content',
          padding: 12,
          margin: 'auto',
        }}
        gap="md"
      >
        <NoCookieSvg />
        <Text variant="headline7" css={{ '@md': typography.headline5 }}>
          {t('common:cookies_are_required')}
        </Text>
        <Stack gap="xs">
          <Text
            variant="body5"
            css={{ '@md': typography.body4 }}
            align="center"
          >
            {t('common:cookies_are_required_description_01')}
          </Text>
          <Text
            variant="body5"
            css={{ '@md': typography.body4 }}
            align="center"
          >
            {t('common:cookies_are_required_description_02')}
          </Text>
        </Stack>
        <Stack.Item direction="horizontal" css={{ mt: 16 }}>
          <Button
            variant="primary"
            size="medium"
            css={{ minWidth: 123 }}
            onClick={() => {
              onClose();
              onConfirm();
            }}
          >
            {t('common:refresh')}
          </Button>
        </Stack.Item>
      </Stack>
    </DialogBackdrop>
  );
}
