import React from 'react';

function Icon(props: any) {
  const { size = 64, color = '#00C800', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M31.997 8.001c-13.254 0-24 10.746-24 24 0 13.255 10.746 24 24 24 13.255 0 24-10.745 24-24 0-3.139-.6-6.131-1.692-8.872a2.667 2.667 0 014.955-1.973 29.263 29.263 0 012.07 10.845c0 16.2-13.132 29.334-29.333 29.334-16.2 0-29.333-13.133-29.333-29.334 0-16.2 13.133-29.333 29.333-29.333 2.529 0 4.986.32 7.332.924a2.667 2.667 0 01-1.33 5.165 24.049 24.049 0 00-6.002-.756zm12.797.447a2.667 2.667 0 013.705-.701 29.485 29.485 0 015.79 5.187 2.667 2.667 0 01-4.052 3.468 24.151 24.151 0 00-4.741-4.248 2.667 2.667 0 01-.702-3.706z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M44.216 22.781a2.667 2.667 0 010 3.771l-16.6 16.602-8.219-8.65a2.667 2.667 0 113.867-3.674l4.449 4.683 12.732-12.732a2.667 2.667 0 013.771 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
