import Button from 'components/elements/button';
import Link from 'next/link';
import { css, styled } from 'styles';
import color from 'styles/color';
import typography from 'styles/typography';

export const StyledHeader = styled('header', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  px: 16,
  py: 16,
  backgroundColor: '$white',
  boxShadow: '0px 2px 8px rgba(27, 30, 39, 0.03)',
  '@md': {
    py: 20,
  },
  position: 'sticky',
  top: 0,
  zIndex: 2,
});

export const StyledHeaderContainer = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  '@sm': {
    maxWidth: 544,
  },
  '@md': {
    maxWidth: 736,
  },
  '@lg': {
    maxWidth: 960,
  },
  '@xl': {
    maxWidth: 1168,
  },
});

export const StyledLeftSideContent = styled('div', {
  display: 'flex',
});

export const StyledRightSideContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  '& > *:not(:first-child)': {
    ml: 12,
  },
  '@lg': {
    '& > *:not(:first-child)': {
      ml: 8,
    },
  },
  '@xl': {
    '& > *:not(:first-child)': {
      ml: 24,
    },
  },
});
export const AsideContainer = styled('div', {
  position: 'fixed',
  inset: 0,
  zIndex: 2,
  backgroundColor: color.overlayDark,
  '& > div': {
    height: '100%',
    maxWidth: 340,
    width: '100%',
    overflow: 'auto',
    backgroundColor: '$white',
  },
});

export const AsideNotificationContainer = styled('div', {
  position: 'fixed',
  inset: 0,
  zIndex: 9999,
  backgroundColor: color.white,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'scroll',
});

export const NotificationContainer = styled('div', {
  position: 'fixed',
  inset: 0,
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$white',
});

export const MobileMenuContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
  width: '100%',
  inset: 0,
});

export const TopMenuContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$secondary',
  pb: 24,
  pt: 16,
  px: 16,
  '& > *:not(:first-child)': {
    mt: 12,
  },
});

export const ProfileContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const ProfileImageContainer = styled('div', {
  // borderRadius: 999,
  // padding: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // backgroundColor: '$primary',
  width: 'fit-content',
  height: 'fit-content',
});

export const ALinkHeader = styled(Link, {
  ...typography.body3,
  color: '$typeGray',
  textDecoration: 'none',
  '& > *:last-child': {
    ml: 4,
  },
});

export const ALinkMenu = styled(Link, {
  ...typography.buttonLarge,
  color: '$typeBlack',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 6,
  padding: 16,
  '&:hover': {
    backgroundColor: '#00000010',
  },
  '&:focus': {
    outlineColor: '#DCD4FF',
    outlineStyle: 'solid',
    outlineWidth: 'medium',
  },
  '& > svg': {
    mr: 16,
  },
});

export const MiniMenu = styled('button', {
  ...typography.buttonLarge,
  color: '$typeBlack',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 6,
  padding: 16,
  '&:hover': {
    backgroundColor: '#00000010',
  },
  '&:focus': {
    outlineColor: '#DCD4FF',
    outlineStyle: 'solid',
    outlineWidth: 'medium',
  },
  '& > svg': {
    mr: 16,
  },
});
export const ButtonMenu = styled(Button, {
  ...typography.buttonLarge,
  color: '$typeBlack !important',
  backgroundColor: 'transparent',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 6,
  padding: 16,
  width: '100%',
  '&:hover': {
    backgroundColor: '#00000010',
  },
  '&:focus': {
    outlineColor: '#DCD4FF',
    outlineStyle: 'solid',
    outlineWidth: 'medium',
  },
  '& > svg': {
    mr: 16,
  },
});
export const ANotification = styled('a', {
  color: '$typeBlack',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#00000010',
  },
  '&:focus': {
    outlineColor: '#DCD4FF',
    outlineStyle: 'solid',
    outlineWidth: 'medium',
  },
  '& > svg': {
    mr: 16,
  },
});

export const MenuContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

export const MenuDesktopContainer = styled('div', {
  display: 'none',
  '@lg': {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:first-child)': {
      ml: 12,
    },
  },
  '@xl': {
    '& > *:not(:first-child)': {
      ml: 16,
    },
  },
});

export const StyledNotification = styled('div', {
  display: 'flex',
  flex: 1,
  padding: 16,
  '&:not(:first-child)': {
    borderTop: '1px solid $dbGray1',
  },
});

export const StyledRightSideNotification = styled('div', {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  ml: 16,
});

export const StyledHeaderNotification = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: 4,
});

export const NotificationHeader = styled('div', {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  borderBottom: '1px solid $dbGray1',
  padding: 16,
  position: 'sticky',
  top: 0,
  backgroundColor: 'White',
});

export const styles = {
  closeContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  logoBig: css({
    display: 'none',
    mr: 24,
    '@lg': {
      display: 'flex',
      alignItems: 'center',
    },
  }),
  logoSmall: css({
    display: 'flex',
    alignItems: 'center',
    mr: 8,
    '@lg': {
      display: 'none',
    },
  }),
  shadow: css({
    boxShadow: '0 0 6px #00000040',
    borderRadius: 9999,
  }),
};
