import { WhatsappIcon } from 'assets/images/svg';
import BottomSheet from 'components/widgets/bottom-sheet/bottom-sheet';
import FloatingButtonLink from 'components/widgets/floating-button/floating-button';
import { Footer } from 'components/widgets/footer';
import { Header } from 'components/widgets/header';
// import Handler from 'handler';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { CSS } from 'styles';

import { Main } from './style';

export type DefaultLayoutProps = {
  children: React.ReactNode;
  mainClassName?: string;
  mainCss?: CSS;
  isShowFooter?: boolean;
  isShowFloatingButton?: boolean;
  isShowBottomSheet?: boolean;
};

const CookieStorageKey = 'confirm-cookies';

export default function DefaultLayout(props: DefaultLayoutProps) {
  const {
    children,
    mainClassName,
    mainCss,
    isShowFooter = false,
    isShowFloatingButton = false,
    isShowBottomSheet = false,
  } = props;

  const [render, setRender] = React.useState(false);

  const [isShowCookieAlert, setIsShowCookieAlert] =
    React.useState(isShowBottomSheet);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    const hasConfirmCookie = localStorage.getItem(CookieStorageKey) === 'true';
    setIsShowCookieAlert(isShowCookieAlert && !hasConfirmCookie);
  }, [isShowCookieAlert]);

  React.useEffect(() => {
    setRender(true);
  }, []);

  if (!render) return null;

  return (
    <>
      {/* <Handler /> */}
      <Header />
      <Main css={mainCss} className={mainClassName}>
        {children}
        {isShowFloatingButton && (
          <FloatingButtonLink
            link="https://wa.me/+628116000227"
            children={<WhatsappIcon />}
          />
        )}
      </Main>

      {isShowFooter && (
        <>
          <BottomSheet
            isOpen={isShowCookieAlert}
            description={t('common:no_cookies')}
            onClose={() => {
              localStorage.setItem(CookieStorageKey, 'true');
              setIsShowCookieAlert(false);
            }}
          />
          <Footer />
        </>
      )}
    </>
  );
}
