import React from 'react';

function Icon(props: any) {
  const { size = 64, color = '#104FA6', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        fill={color}
        d="M57.33 22.635V45a7 7 0 01-7 7H13.665a7 7 0 01-7-7V22.635l24.53 13.493c.5.275 1.107.275 1.606 0l24.53-13.495v.002zM50.33 12a7 7 0 016.998 6.832l-25.33 13.933L6.666 18.831l.002-.12A7 7 0 0113.664 12h36.667z"
      />
    </svg>
  );
}

export default Icon;
