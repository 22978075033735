import React from 'react';

function Icon(props: any) {
  const { size = 129, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size + 9}
      height={size}
      fill="none"
      viewBox="0 0 138 129"
      {...rest}
    >
      <path
        fill="#C9CCCF"
        fillRule="evenodd"
        d="M19.189 39.33a5.834 5.834 0 00-5.834 5.833v10.293a10.833 10.833 0 010 21.082V86.83a5.833 5.833 0 005.834 5.833h39.166V86.83a2.5 2.5 0 015 0v5.833h14.167a5.833 5.833 0 005.834-5.833V76.538a10.834 10.834 0 010-21.082V45.163a5.833 5.833 0 00-5.834-5.833H63.355v5.833a2.5 2.5 0 01-5 0V39.33H19.19zm0-5h58.333a10.833 10.833 0 0110.834 10.833v12.5a2.5 2.5 0 01-2.5 2.5 5.833 5.833 0 100 11.667 2.5 2.5 0 012.5 2.5v12.5a10.833 10.833 0 01-10.834 10.833H19.19A10.833 10.833 0 018.355 86.83v-12.5a2.5 2.5 0 012.5-2.5 5.833 5.833 0 000-11.667 2.5 2.5 0 01-2.5-2.5v-12.5A10.833 10.833 0 0119.19 34.33zm41.666 25a2.5 2.5 0 012.5 2.5v8.333a2.5 2.5 0 01-5 0V61.83a2.5 2.5 0 012.5-2.5z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M100.257 69.103c-14.885 0-26.951 12.066-26.951 26.951s12.066 26.952 26.951 26.952 26.952-12.067 26.952-26.952c0-3.572-.694-6.975-1.95-10.087a2.994 2.994 0 115.553-2.242 32.851 32.851 0 012.386 12.33c0 18.192-14.748 32.94-32.941 32.94-18.193 0-32.94-14.748-32.94-32.94 0-18.194 14.748-32.942 32.94-32.942 3.138 0 6.178.44 9.059 1.262a2.994 2.994 0 11-1.644 5.76 26.97 26.97 0 00-7.415-1.032zm14.37.502a2.995 2.995 0 014.161-.788 33.104 33.104 0 016.502 5.825 2.995 2.995 0 11-4.55 3.895 27.097 27.097 0 00-5.325-4.771 2.996 2.996 0 01-.788-4.161z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M113.226 83.457a2.994 2.994 0 010 4.235L91.889 109.03a2.996 2.996 0 01-4.235-4.235l21.337-21.337a2.995 2.995 0 014.235 0z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M87.66 83.457a2.995 2.995 0 000 4.235l21.337 21.337a2.995 2.995 0 004.235-4.235L91.896 83.457a2.995 2.995 0 00-4.235 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
