import Panel, {
  PanelHandler,
  SinglePanelProps,
} from 'components/widgets/panel';
import { SidePanelOption } from 'components/widgets/panel/side-panel';
import produce from 'immer';
import React from 'react';

const PanelContext = React.createContext<PanelHandler | undefined>(undefined);

export function PanelProvider({ children }: any) {
  const [panels, setPanels] = React.useState<SinglePanelProps[]>();

  const onAddPanel = React.useCallback((option: SidePanelOption) => {
    setPanels((prevPanels) =>
      //@ts-ignore
      (
        prevPanels?.map((item) => {
          return {
            ...item,
            panelOption: {
              ...item.panelOption,
              width: '100%',
            },
          };
        }) || []
      ).concat({
        panelOption: {
          ...option,
          width: option.width ? option.width : 600,
        },
      }),
    );
  }, []);

  const onClosePanel = React.useCallback(() => {
    setPanels((prevPanels) =>
      produce(prevPanels, (draft) => {
        draft?.pop();
        if (draft && draft.length)
          draft[draft.length - 1].panelOption.width = '80%';
      }),
    );
  }, []);

  return (
    <>
      <PanelContext.Provider
        value={{ panels, setPanels, onAddPanel, onClosePanel }}
      >
        {children}
      </PanelContext.Provider>
      {panels?.map((item) => {
        return <Panel onClose={onClosePanel} option={item.panelOption} />;
      })}
    </>
  );
}

export function usePanel() {
  const context = React.useContext(PanelContext);
  if (context === undefined) {
    throw new Error('usePanel must be used within PanelProvider');
  }
  return context;
}
