import React from 'react';

function Icon(props: any) {
  const { size = 186, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={typeof size === 'number' ? size + 24 : size}
      height={size}
      fill="none"
      viewBox="0 0 210 186"
      {...rest}
    >
      <path
        fill="#EBEBEB"
        d="M209.823 127.393H0v.104h209.823v-.104zM188.797 134.145h-13.899v.104h13.899v-.104zM138.994 135.285h-3.646v.105h3.646v-.105zM174.479 130.25h-8.053v.105h8.053v-.105zM40.14 130.955H22.016v.105H40.14v-.105zM46.535 130.955H43.88v.105h2.656v-.105zM94.484 132.727H55.172v.104h39.312v-.104z"
      />
      <path
        fill="#FAFAFA"
        d="M117.631 37.24H23.547s4.284-7.555 12.45-7.555c8.167 0 .508 4.197 6.837 4.197 6.328 0 12.858-13.571 32.959-13.571 20.1 0 3.47 10.205 13.877 10.205 10.408 0 43.728-9.043 27.961 6.723zM188.902 49.33h-47.043s2.145-3.777 6.228-3.777.252 2.098 3.416 2.098c3.164 0 6.429-6.786 16.479-6.786 10.051 0 1.738 5.103 6.941 5.103 5.204 0 21.864-4.524 13.979 3.361zM96.63 8.993c3.97 0 .248 2.036 3.324 2.036 3.076 0 6.261-6.605 16.043-6.605 9.614 0 2.358 5.619 6.915 5.812h.248c4.566 0 9.278-9.79 23.777-9.79s2.518 7.36 10.009 7.36c7.49 0 31.544-6.509 20.172 4.855H90.57s2.086-3.668 6.06-3.668z"
      />
      <path
        fill="#E0E0E0"
        d="M41.78 5.946a9.922 9.922 0 00-2.001-.566 9.069 9.069 0 00-2.099-.097 8.915 8.915 0 00-1.548.265 9.58 9.58 0 00-4.935-3.13 8.19 8.19 0 00-2.002-.299c.648.18 1.273.436 1.86.764.587.315 1.14.69 1.649 1.12a9.034 9.034 0 012.379 3.13l.382.84.726-.449a7.738 7.738 0 013.55-1.158 8.813 8.813 0 011.935.075 8.31 8.31 0 011.922.517 8.838 8.838 0 00-1.817-1.012zM54.828 14.146a7.688 7.688 0 00-1.645-.184c-.566 0-1.13.068-1.679.201-.405.104-.8.244-1.18.42a8.364 8.364 0 00-1.06-.764 7.917 7.917 0 00-1.579-.718 7.728 7.728 0 00-1.632-.335 6.668 6.668 0 00-1.612.03 6.581 6.581 0 011.557.343 7.29 7.29 0 013.722 2.812l.42.596.512-.45a6.16 6.16 0 012.644-1.38 7.129 7.129 0 011.532-.193 6.484 6.484 0 011.573.155 7.006 7.006 0 00-1.573-.533zM173.648 23.846c-.76.201-1.497.482-2.199.839-.71.376-1.374.835-1.976 1.368a9.94 9.94 0 00-1.217 1.3 10.937 10.937 0 00-6.597.546 9.588 9.588 0 00-2.018 1.108 9.774 9.774 0 012.194-.6c.749-.12 1.509-.16 2.266-.122a10.3 10.3 0 014.302 1.196l.906.479.349-.907a8.804 8.804 0 012.413-3.474 9.975 9.975 0 011.804-1.26 9.34 9.34 0 012.098-.838c-.786.03-1.567.152-2.325.365z"
      />
      <path
        fill="#F5F5F5"
        d="M104.912 146.343c44.937 0 81.365-2.127 81.365-4.751 0-2.623-36.428-4.75-81.365-4.75s-81.365 2.127-81.365 4.75c0 2.624 36.428 4.751 81.365 4.751z"
      />
      <path
        fill="#327FEB"
        d="M62.46 80.855l17.653 5.483 2.842-9.15L65.3 71.706l-2.841 9.15z"
      />
      <path
        fill="#FAFAFA"
        d="M62.46 80.855l17.653 5.483 2.842-9.15L65.3 71.706l-2.841 9.15z"
        opacity="0.4"
      />
      <path
        fill="#327FEB"
        d="M65.302 71.702l-4.536-1.409-2.842 9.15 4.537 1.408 2.841-9.15z"
      />
      <path
        fill="#FAFAFA"
        d="M65.302 71.702l-4.536-1.409-2.842 9.15 4.537 1.408 2.841-9.15z"
        opacity="0.4"
      />
      <path
        fill="#000"
        d="M65.302 71.702l-4.536-1.409-2.842 9.15 4.537 1.408 2.841-9.15zM79.485 85.735l.445.138 2.61-8.404-.445-.138-2.61 8.404zM78.504 85.43l.445.138 2.61-8.404-.445-.138-2.61 8.404zM77.532 85.127l.445.139 2.61-8.404-.445-.139-2.61 8.404zM76.551 84.825l.445.138 2.61-8.404-.445-.138-2.61 8.404zM75.575 84.52l.444.138 2.61-8.404-.444-.138-2.61 8.404zM74.602 84.217l.445.139 2.61-8.405-.445-.138-2.61 8.404zM73.618 83.913l.445.138 2.61-8.404-.445-.138-2.61 8.404zM72.645 83.61l.445.138 2.61-8.404-.445-.138-2.61 8.404zM71.668 83.307l.445.138 2.61-8.404-.445-.138-2.61 8.404zM70.688 83.002l.445.139 2.61-8.404-.445-.139-2.61 8.404zM69.715 82.7l.445.138 2.61-8.404-.445-.138-2.61 8.404zM68.735 82.395l.445.138 2.61-8.404-.445-.138-2.61 8.404zM67.762 82.092l.445.139 2.61-8.405-.445-.138-2.61 8.404zM66.782 81.79l.445.138 2.61-8.404-.445-.139-2.61 8.405zM65.805 81.485l.445.138 2.61-8.404-.445-.138-2.61 8.404zM64.832 81.184l.445.138 2.61-8.404-.445-.138-2.61 8.404zM63.848 80.88l.445.138 2.61-8.404-.445-.139-2.61 8.404zM62.872 80.575l.444.138 2.61-8.404-.445-.138-2.61 8.404z"
        opacity="0.2"
      />
      <path
        fill="#327FEB"
        d="M41.545 74.361L59.2 79.844l2.842-9.15-17.654-5.482-2.842 9.15z"
      />
      <path
        fill="#327FEB"
        d="M44.388 65.208l-4.536-1.41-2.842 9.15 4.537 1.41 2.841-9.15z"
      />
      <path
        fill="#000"
        d="M44.388 65.208l-4.536-1.41-2.842 9.15 4.537 1.41 2.841-9.15zM58.57 79.239l.446.138 2.61-8.404-.445-.138-2.61 8.404zM57.594 78.936l.445.138 2.61-8.404-.445-.138-2.61 8.404zM56.618 78.633l.444.139 2.61-8.405-.444-.138-2.61 8.404zM55.637 78.329l.445.138 2.61-8.404-.445-.138-2.61 8.404zM54.664 78.026l.445.138 2.61-8.404-.445-.138-2.61 8.404zM53.684 77.721l.445.138 2.61-8.404-.445-.138-2.61 8.404zM52.708 77.418l.444.139 2.61-8.404-.445-.139-2.61 8.404zM51.735 77.116l.445.138 2.61-8.404-.445-.138-2.61 8.404zM50.75 76.811l.445.138 2.61-8.404-.445-.138-2.61 8.404zM49.774 76.508l.445.139 2.61-8.405-.445-.138-2.61 8.404zM48.801 76.206l.445.138 2.61-8.404-.445-.138-2.61 8.404zM47.82 75.9l.446.14 2.61-8.405-.445-.138-2.61 8.404zM46.848 75.598l.445.138 2.61-8.404-.445-.138-2.61 8.404zM45.868 75.293l.444.139 2.61-8.404-.444-.139-2.61 8.404zM44.887 74.99l.445.139 2.61-8.404-.445-.138-2.61 8.404zM43.914 74.688l.445.138 2.61-8.404-.445-.138-2.61 8.404zM42.938 74.383l.445.139 2.61-8.405-.445-.138-2.61 8.404zM41.961 74.083l.445.138 2.61-8.404-.445-.138-2.61 8.404z"
        opacity="0.2"
      />
      <path
        fill="#327FEB"
        d="M56.737 89.105l17.654 5.483 2.841-9.15-17.654-5.482-2.841 9.15z"
      />
      <path
        fill="#327FEB"
        d="M59.58 79.956l-4.537-1.41-2.841 9.15 4.536 1.41 2.842-9.15z"
      />
      <path
        fill="#000"
        d="M59.58 79.956l-4.537-1.41-2.841 9.15 4.536 1.41 2.842-9.15zM73.762 93.985l.445.138 2.61-8.404-.445-.138-2.61 8.404zM72.782 93.68l.445.138 2.61-8.404-.445-.138-2.61 8.404zM71.805 93.377l.445.139 2.61-8.404-.445-.139-2.61 8.404zM70.832 93.075l.445.138 2.61-8.404-.445-.138-2.61 8.404zM69.848 92.77l.445.138 2.61-8.404-.445-.138-2.61 8.404zM68.876 92.467l.444.139 2.61-8.405-.445-.138-2.61 8.404zM67.899 92.163l.445.138 2.61-8.404-.445-.138-2.61 8.404zM66.918 91.86l.445.138 2.61-8.404-.445-.138-2.61 8.404zM65.946 91.557l.445.138L69 83.291l-.445-.138-2.61 8.404zM64.965 91.252l.445.139 2.61-8.404-.445-.139-2.61 8.404zM63.989 90.95l.445.138 2.61-8.404-.445-.138-2.61 8.404zM63.012 90.647l.445.138 2.61-8.404-.445-.138-2.61 8.404zM62.036 90.342l.444.139 2.61-8.405-.445-.138-2.61 8.404zM61.06 90.04l.444.138 2.61-8.404-.445-.139-2.61 8.405zM60.079 89.735l.444.138 2.61-8.404-.445-.138-2.61 8.404zM59.102 89.432l.445.138 2.61-8.404-.445-.138-2.61 8.404zM58.126 89.13l.444.138 2.61-8.404-.445-.139-2.61 8.404zM57.149 88.825l.445.138 2.61-8.404-.445-.138-2.61 8.404z"
        opacity="0.2"
      />
      <path
        fill="#327FEB"
        d="M37.116 83.012l17.654 5.482 2.84-9.15-17.653-5.482-2.841 9.15z"
      />
      <path
        fill="#FAFAFA"
        d="M37.116 83.012l17.654 5.482 2.84-9.15-17.653-5.482-2.841 9.15z"
        opacity="0.4"
      />
      <path
        fill="#327FEB"
        d="M39.959 73.864l-4.537-1.409-2.841 9.15 4.536 1.408 2.842-9.149z"
      />
      <path
        fill="#FAFAFA"
        d="M39.959 73.864l-4.537-1.409-2.841 9.15 4.536 1.408 2.842-9.149z"
        opacity="0.4"
      />
      <path
        fill="#000"
        d="M39.959 73.864l-4.537-1.409-2.841 9.15 4.536 1.408 2.842-9.149zM54.141 87.891l.445.138 2.61-8.404-.445-.138-2.61 8.404zM53.16 87.587l.446.138 2.61-8.404-.446-.139-2.61 8.404zM52.184 87.284l.445.138 2.61-8.404-.445-.138-2.61 8.404zM51.211 86.981l.445.138 2.61-8.404-.445-.138-2.61 8.404zM50.227 86.676l.445.138 2.61-8.404-.445-.138-2.61 8.404zM49.254 86.374l.445.138 2.61-8.404-.445-.139-2.61 8.405zM48.278 86.069l.445.138 2.61-8.404-.445-.138-2.61 8.404zM47.297 85.766l.445.138 2.61-8.404-.445-.138-2.61 8.404zM46.325 85.463l.444.139 2.61-8.404-.444-.139-2.61 8.404zM45.344 85.159l.445.138 2.61-8.404-.445-.138-2.61 8.404zM44.368 84.856l.444.138 2.61-8.404-.444-.138-2.61 8.404zM43.391 84.553l.445.138 2.61-8.404L46 76.15l-2.61 8.404zM42.414 84.249l.445.138 2.61-8.404-.445-.139-2.61 8.405zM41.438 83.946l.445.138 2.61-8.404-.445-.138-2.61 8.404zM40.458 83.641l.444.138 2.61-8.404-.445-.138-2.61 8.404zM39.48 83.338l.446.139 2.61-8.404-.445-.139-2.61 8.404zM38.504 83.036l.445.138 2.61-8.404-.445-.138-2.61 8.404zM37.528 82.731l.445.138 2.61-8.404-.445-.138-2.61 8.404z"
        opacity="0.2"
      />
      <path
        fill="#327FEB"
        d="M94.585 45.327l-8.28-2.571-3.993 12.856 8.28 2.572 3.993-12.857z"
      />
      <path
        fill="#fff"
        d="M94.178 46.632l-8.28-2.572-.411 1.327 8.28 2.571.411-1.326zM93.291 49.49l-8.28-2.572-.317 1.022 8.28 2.571.317-1.022z"
      />
      <path
        fill="#327FEB"
        d="M78.932 40.464l-8.28-2.571-3.992 12.856 8.28 2.571 3.992-12.856z"
      />
      <path
        fill="#fff"
        d="M78.526 41.77l-8.28-2.57-.412 1.326 8.28 2.571.412-1.326zM77.64 44.628l-8.28-2.571-.318 1.022 8.28 2.57.317-1.021z"
      />
      <path
        fill="#E0E0E0"
        d="M66.683 56.694l-10.492-3.259-10.055 32.378 10.492 3.259 10.055-32.378z"
      />
      <path
        fill="#fff"
        d="M63.09 60.425l-7.106-2.206-.216.697 7.105 2.207.217-.698zM62.25 63.13l-7.105-2.206-.217.697 7.105 2.207.217-.698zM61.41 65.832l-7.105-2.207-.217.697 7.106 2.207.216-.697zM60.574 68.533l-7.105-2.207-.217.698 7.106 2.206.216-.697zM59.734 71.234l-7.105-2.207-.217.698 7.106 2.206.216-.697zM58.895 73.94l-7.106-2.208-.217.698 7.106 2.206.217-.697zM58.055 76.64l-7.106-2.206-.216.697 7.105 2.207.217-.698zM57.215 79.341l-7.106-2.206-.216.697 7.105 2.207.217-.698z"
        opacity="0.7"
      />
      <path
        fill="#FAFAFA"
        d="M56.628 89.068l28.9 8.975 10.054-32.378-28.899-8.975-10.055 32.378z"
      />
      <g fill="#000" opacity="0.8">
        <path
          d="M69.142 61.36l-.384-.12-.498 1.603.385.12.498-1.603zM74.682 63.08l-.385-.12-.498 1.604.385.12.498-1.604zM80.22 64.8l-.384-.12-.498 1.603.385.12.498-1.604zM85.76 66.52l-.385-.12-.498 1.603.385.12.498-1.603zM91.295 68.24l-.385-.119-.498 1.603.385.12.498-1.603zM67.568 66.434l-.384-.12-.498 1.604.384.119.498-1.603zM73.107 68.153l-.384-.12-.498 1.603.385.12.497-1.603zM78.642 69.873l-.384-.12-.498 1.604.385.12.498-1.604zM84.182 71.594l-.385-.12-.498 1.604.385.12.498-1.604zM89.72 73.313l-.384-.12-.498 1.603.385.12.498-1.603zM65.994 71.502l-.385-.12-.497 1.604.384.12.498-1.604zM71.53 73.223l-.386-.12-.497 1.604.384.12.498-1.604zM77.068 74.944l-.384-.12-.498 1.603.384.12.498-1.603zM82.607 76.662l-.384-.119-.498 1.603.385.12.497-1.604zM88.147 78.383l-.385-.12-.498 1.604.385.12.498-1.604zM64.416 76.576l-.385-.119-.498 1.603.385.12.498-1.604zM69.955 78.297l-.385-.12-.497 1.604.384.12.498-1.604zM75.494 80.016l-.385-.12-.497 1.603.384.12.498-1.603zM81.033 81.737l-.385-.12-.497 1.603.384.12.498-1.603zM86.572 83.457l-.385-.12-.497 1.604.384.12.498-1.604zM62.842 81.647l-.385-.12-.498 1.603.385.12.498-1.603zM68.38 83.366l-.384-.12-.498 1.603.385.12.498-1.603zM73.92 85.086l-.385-.12-.498 1.604.385.12.498-1.604zM79.459 86.807l-.385-.12-.498 1.604.385.119.498-1.603zM84.998 88.526l-.385-.12-.498 1.603.385.12.498-1.603zM61.267 86.721l-.384-.12-.498 1.604.385.12.497-1.604zM66.807 88.44l-.385-.12-.498 1.603.385.12.498-1.603zM72.346 90.16l-.385-.119-.498 1.603.385.12.498-1.603zM77.88 91.88l-.384-.12-.498 1.603.385.12.498-1.604zM83.42 93.6l-.385-.12-.498 1.603.385.12.498-1.603z"
          opacity="0.2"
        />
      </g>
      <path
        fill="#E0E0E0"
        d="M70.547 49.936l-8.656-2.688-2.253 7.254 8.656 2.688 2.253-7.254z"
      />
      <path
        fill="#FAFAFA"
        d="M68.295 57.195l23.838 7.403 2.252-7.254-23.837-7.403-2.253 7.254z"
      />
      <path
        fill="#000"
        d="M61.444 48.706l32.493 10.09.451-1.45-32.494-10.091-.45 1.45z"
        opacity="0.1"
      />
      <path
        fill="#000"
        d="M72.69 53.458l-1.311-.407-.407 1.31 1.31.407.407-1.31zM74.611 54.055l-1.31-.407-.407 1.31 1.31.408.407-1.31zM76.541 54.655l-1.31-.407-.407 1.31 1.31.407.407-1.31zM78.463 55.25l-1.31-.406-.408 1.31 1.31.407.408-1.31zM80.389 55.848l-1.31-.407-.408 1.31 1.31.408.408-1.31zM82.31 56.446L81 56.039l-.407 1.31 1.31.408.407-1.311zM84.24 57.046l-1.31-.407-.407 1.31 1.31.407.407-1.31zM86.162 57.643l-1.31-.407-.407 1.31 1.31.408.407-1.31zM88.088 58.239l-1.31-.407-.408 1.31 1.31.407.408-1.31zM90.01 58.837l-1.31-.407-.408 1.31 1.31.407.408-1.31zM91.94 59.436l-1.311-.407-.407 1.31 1.31.408.407-1.31zM72.1 55.362l-1.31-.407-.408 1.31 1.31.408.408-1.31zM74.021 55.96l-1.31-.407-.407 1.31 1.31.407.407-1.31zM75.951 56.557l-1.31-.407-.407 1.31 1.31.408.407-1.31zM77.873 57.155l-1.31-.407-.407 1.31 1.31.407.407-1.31zM79.799 57.753l-1.31-.407-.408 1.31 1.31.407.408-1.31zM81.72 58.35l-1.31-.407-.407 1.31 1.31.408.408-1.31zM83.646 58.948l-1.31-.407-.407 1.31 1.31.407.407-1.31zM85.572 59.546l-1.31-.407-.407 1.31 1.31.407.407-1.31zM87.494 60.143l-1.31-.407-.407 1.31 1.31.408.407-1.31zM89.42 60.741l-1.31-.407-.408 1.31 1.31.407.408-1.31zM91.346 61.339l-1.31-.407-.408 1.31 1.31.407.408-1.31z"
        opacity="0.2"
      />
      <path
        fill="#E0E0E0"
        d="M70.38 45.455L58.323 41.71l.525 4.592 10.276 3.191 1.254-4.04z"
      />
      <path
        fill="#FAFAFA"
        d="M70.379 45.455l30.081 9.342-3.034 3.487-28.302-8.79 1.255-4.039z"
      />
      <path
        fill="#327FEB"
        d="M111.133 105.998l23.609 7.332 2.842-9.149-23.609-7.332-2.842 9.149z"
      />
      <path
        fill="#FAFAFA"
        d="M111.133 105.998l23.609 7.332 2.842-9.149-23.609-7.332-2.842 9.149z"
        opacity="0.4"
      />
      <path
        fill="#327FEB"
        d="M113.974 96.85l-6.064-1.883-2.841 9.149 6.063 1.883 2.842-9.15z"
      />
      <path
        fill="#FAFAFA"
        d="M113.974 96.85l-6.064-1.883-2.841 9.149 6.063 1.883 2.842-9.15z"
        opacity="0.4"
      />
      <path
        fill="#000"
        d="M113.974 96.85l-6.064-1.883-2.841 9.149 6.063 1.883 2.842-9.15zM133.864 112.65l.593.184 2.61-8.404-.593-.184-2.61 8.404zM132.555 112.244l.593.184 2.61-8.404-.593-.184-2.61 8.404zM131.251 111.837l.593.184 2.61-8.404-.593-.184-2.61 8.404zM129.946 111.433l.593.184 2.61-8.404-.593-.184-2.61 8.404zM128.637 111.027l.593.184 2.61-8.404-.593-.184-2.61 8.404zM127.333 110.62l.593.185 2.61-8.404-.593-.185-2.61 8.404zM126.024 110.214l.593.184 2.61-8.404-.593-.184-2.61 8.404zM124.719 109.81l.593.184 2.61-8.404-.593-.184-2.61 8.404zM123.411 109.404l.593.184 2.61-8.404-.593-.184-2.61 8.404zM122.106 108.997l.593.185 2.61-8.404-.593-.185-2.61 8.404zM120.798 108.591l.593.184 2.61-8.404-.594-.184-2.609 8.404zM119.493 108.187l.593.184 2.61-8.404-.593-.184-2.61 8.404zM118.184 107.781l.593.184 2.61-8.404-.593-.184-2.61 8.404zM116.88 107.374l.593.185 2.61-8.404-.594-.185-2.609 8.404zM115.571 106.968l.593.184 2.61-8.404-.593-.184-2.61 8.404zM114.266 106.564l.593.184 2.61-8.404-.593-.184-2.61 8.404zM112.962 106.158l.593.184 2.61-8.404-.594-.184-2.609 8.404zM111.653 105.751l.593.185 2.61-8.405-.593-.184-2.61 8.404z"
        opacity="0.2"
      />
      <path
        fill="#327FEB"
        d="M83.168 97.315l23.609 7.331 2.842-9.149-23.61-7.332-2.84 9.15zM86.009 88.164l-6.064-1.883-2.841 9.15 6.064 1.883 2.84-9.15z"
      />
      <path
        fill="#000"
        d="M86.009 88.164l-6.064-1.883-2.841 9.15 6.064 1.883 2.84-9.15zM105.899 103.964l.593.184 2.61-8.404-.593-.184-2.61 8.404zM104.59 103.558l.594.184 2.61-8.404-.594-.184-2.61 8.404zM103.286 103.154l.593.184 2.61-8.404-.593-.184-2.61 8.404zM101.977 102.747l.593.185 2.61-8.404-.593-.185-2.61 8.404zM100.672 102.341l.594.184 2.61-8.404-.594-.184-2.61 8.404zM99.364 101.937l.593.184 2.61-8.404-.593-.184-2.61 8.404zM98.06 101.531l.592.184 2.61-8.404-.593-.184-2.61 8.404zM96.754 101.124l.594.185 2.61-8.404-.594-.185-2.61 8.404zM95.446 100.718l.593.184 2.61-8.404-.593-.184-2.61 8.404zM94.141 100.314l.593.184 2.61-8.404-.593-.184-2.61 8.404zM92.833 99.908l.593.184 2.61-8.404-.593-.184-2.61 8.404zM91.528 99.501l.593.184 2.61-8.404-.593-.184-2.61 8.404zM90.22 99.095l.593.184 2.61-8.404-.594-.184-2.61 8.404zM88.915 98.69l.593.185 2.61-8.404-.593-.184-2.61 8.404zM87.606 98.285l.593.184 2.61-8.404-.593-.184-2.61 8.404zM86.301 97.878l.593.184 2.61-8.403-.593-.185-2.61 8.404zM84.993 97.472l.593.184 2.61-8.404-.593-.184-2.61 8.404zM83.688 97.068l.593.184 2.61-8.404-.593-.184-2.61 8.404z"
        opacity="0.2"
      />
      <path
        fill="#327FEB"
        d="M110.121 95.658l23.609 7.332 2.842-9.15-23.609-7.331-2.842 9.15zM112.962 86.502l-6.064-1.883-2.841 9.15 6.064 1.883 2.841-9.15z"
      />
      <path
        fill="#000"
        d="M112.962 86.502l-6.064-1.883-2.841 9.15 6.064 1.883 2.841-9.15zM132.848 102.306l.593.184 2.61-8.404-.593-.184-2.61 8.404zM131.544 101.902l.593.184 2.61-8.404-.593-.184-2.61 8.404zM130.235 101.495l.593.185 2.61-8.404-.593-.185-2.61 8.404zM128.93 101.089l.593.184 2.61-8.404-.593-.184-2.61 8.404zM127.622 100.683l.593.184 2.61-8.404-.593-.184-2.61 8.404zM126.317 100.279l.593.184 2.61-8.404-.593-.184-2.61 8.404zM125.008 99.872l.594.185 2.609-8.404-.593-.185-2.61 8.404zM123.704 99.466l.593.184 2.61-8.404-.593-.184-2.61 8.404zM122.395 99.06l.593.184 2.61-8.404-.593-.184-2.61 8.404zM121.09 98.656l.594.184 2.61-8.404-.594-.184-2.61 8.404zM119.782 98.25l.593.184 2.61-8.404-.593-.185-2.61 8.404zM118.477 97.843l.593.184 2.61-8.404-.593-.184-2.61 8.404zM117.165 97.437l.593.184 2.61-8.404-.593-.184-2.61 8.404zM115.86 97.03l.593.185 2.61-8.404-.593-.184-2.61 8.404zM114.555 96.624l.593.185 2.61-8.404-.593-.185-2.61 8.404zM113.247 96.22l.593.184L116.45 88l-.593-.184-2.61 8.404zM111.942 95.814l.593.184 2.61-8.404-.593-.184-2.61 8.404zM110.633 95.408l.594.184 2.609-8.404-.593-.184-2.61 8.404z"
        opacity="0.2"
      />
      <path
        fill="#327FEB"
        d="M83.88 87.508l23.608 7.332 2.842-9.15-23.61-7.331-2.84 9.149z"
      />
      <path
        fill="#FAFAFA"
        d="M83.88 87.508l23.608 7.332 2.842-9.15-23.61-7.331-2.84 9.149z"
        opacity="0.4"
      />
      <path
        fill="#327FEB"
        d="M86.724 78.354L80.66 76.47l-2.841 9.15 6.063 1.882 2.842-9.15z"
      />
      <path
        fill="#FAFAFA"
        d="M86.724 78.354L80.66 76.47l-2.841 9.15 6.063 1.882 2.842-9.15z"
        opacity="0.4"
      />
      <path
        fill="#000"
        d="M86.724 78.354L80.66 76.47l-2.841 9.15 6.063 1.882 2.842-9.15zM106.61 94.158l.593.184 2.61-8.404-.593-.184-2.61 8.404zM105.305 93.753l.593.184 2.61-8.403-.593-.185-2.61 8.404zM103.997 93.347l.593.184 2.61-8.404-.593-.184-2.61 8.404zM102.692 92.94l.593.185 2.61-8.404-.593-.184-2.61 8.404zM101.383 92.535l.594.184 2.609-8.404-.593-.184-2.61 8.404zM100.079 92.13l.593.184 2.61-8.404-.593-.184-2.61 8.404zM98.77 91.724l.593.184 2.61-8.404-.593-.184-2.61 8.404zM97.466 91.318l.593.184 2.61-8.404-.594-.184-2.61 8.404zM96.157 90.912l.593.184 2.61-8.404-.593-.184-2.61 8.404zM94.852 90.507l.593.184 2.61-8.404-.593-.184-2.61 8.404zM93.544 90.101l.593.184 2.61-8.404-.594-.184-2.61 8.404zM92.239 89.695l.593.184 2.61-8.404-.593-.184-2.61 8.404zM90.934 89.29l.593.185 2.61-8.404-.593-.185-2.61 8.404zM89.626 88.884l.593.184 2.61-8.404-.593-.184-2.61 8.404zM88.32 88.478l.594.184 2.61-8.404-.593-.184-2.61 8.404zM87.012 88.072l.594.184 2.61-8.404-.594-.184-2.61 8.404zM85.708 87.667l.593.185 2.61-8.404-.593-.185-2.61 8.404zM84.4 87.261l.592.184 2.61-8.404-.593-.184-2.61 8.404z"
        opacity="0.2"
      />
      <path
        fill="#327FEB"
        d="M105.683 96.551l-67.42-20.938a.363.363 0 00-.493.172l-2.165 6.97a.348.348 0 00.642.199l2.102-6.77 6.877 2.137-2.102 6.769a.349.349 0 00.641.199l2.102-6.77 6.857 2.13-2.102 6.77a.348.348 0 00.641.198l2.102-6.769 6.85 2.127-2.103 6.77a.349.349 0 00.642.198l2.102-6.769 6.853 2.129-2.102 6.769a.348.348 0 00.645.2l2.102-6.769 6.853 2.128-2.102 6.77a.348.348 0 00.641.199l2.102-6.77 6.857 2.13-2.102 6.769a.349.349 0 00.642.2l2.102-6.77 6.857 2.13-2.102 6.769a.349.349 0 00.64.199l2.103-6.77 6.857 2.13-2.102 6.769a.348.348 0 00.641.199l2.102-6.768 6.853 2.128-2.102 6.769a.346.346 0 00.245.343.348.348 0 00.396-.144l2.18-7.018a.31.31 0 00-.232-.344z"
      />
      <path
        fill="#FAFAFA"
        d="M105.683 96.551l-67.42-20.938a.363.363 0 00-.493.172l-2.165 6.97a.348.348 0 00.642.199l2.102-6.77 6.877 2.137-2.102 6.769a.349.349 0 00.641.199l2.102-6.77 6.857 2.13-2.102 6.77a.348.348 0 00.641.198l2.102-6.769 6.85 2.127-2.103 6.77a.349.349 0 00.642.198l2.102-6.769 6.853 2.129-2.102 6.769a.348.348 0 00.645.2l2.102-6.769 6.853 2.128-2.102 6.77a.348.348 0 00.641.199l2.102-6.77 6.857 2.13-2.102 6.769a.349.349 0 00.642.2l2.102-6.77 6.857 2.13-2.102 6.769a.349.349 0 00.64.199l2.103-6.77 6.857 2.13-2.102 6.769a.348.348 0 00.641.199l2.102-6.768 6.853 2.128-2.102 6.769a.346.346 0 00.245.343.348.348 0 00.396-.144l2.18-7.018a.31.31 0 00-.232-.344z"
        opacity="0.6"
      />
      <path
        fill="#327FEB"
        d="M118.206 108.195a6.129 6.129 0 004.133-.18l15.662-6.398a6.128 6.128 0 014.137-.179l53.302 16.553a1.15 1.15 0 01.8 1.132 1.127 1.127 0 01-.122.476c-4.707 9.427-9.766 15.023-15.059 19.672-.83.731-1.664 1.438-2.505 2.135l-.83.678c-.869.706-1.752 1.398-2.613 2.102a126.003 126.003 0 00-9.768 8.391 71.785 71.785 0 00-8.866 10.495 8.219 8.219 0 01-9.182 3.134L28.954 129.454a8.203 8.203 0 01-5.731-8.683l1.237-11.967 1.146-11.047.292-2.823.093-.907.296-2.821 1.025-9.824a1.142 1.142 0 011.47-.971l89.424 27.784zm59.613 9.246a1.268 1.268 0 00.75.628l10.163 3.156a1.282 1.282 0 001.607-.845 1.287 1.287 0 00-.089-.979 1.287 1.287 0 00-.756-.628l-10.183-3.163a1.29 1.29 0 00-1.608.846 1.262 1.262 0 00.116.985zm-20.971-8.411a1.284 1.284 0 00-.754-.63l-10.183-3.162a1.276 1.276 0 00-1.371.402 1.283 1.283 0 00-.144 1.421 1.26 1.26 0 00.753.629l10.164 3.157a1.28 1.28 0 001.515-1.824l.02.007zm16.7 5.186a1.263 1.263 0 00-.754-.63l-10.183-3.162a1.276 1.276 0 00-1.371.402 1.279 1.279 0 00-.145 1.421 1.263 1.263 0 00.754.63l10.163 3.156a1.285 1.285 0 001.371-.402 1.282 1.282 0 00.145-1.421l.02.006z"
      />
      <path
        fill="#000"
        d="M25.992 94.027l-.093.907-.292 2.823 149.51 46.431c.876-.699 1.751-1.394 2.612-2.102l.83-.678-152.567-47.38zM120.251 120.019l16.94 5.261a2.918 2.918 0 003.651-1.921l.001-.004a2.916 2.916 0 00-1.921-3.65l-16.94-5.261a2.916 2.916 0 00-3.65 1.92l-.001.004a2.917 2.917 0 001.92 3.651zM92.493 111.398l16.94 5.26a2.917 2.917 0 003.651-1.92l.001-.004a2.916 2.916 0 00-1.92-3.65l-16.94-5.261a2.916 2.916 0 00-3.651 1.92l-.001.004a2.917 2.917 0 001.92 3.651zM148.005 128.638l16.94 5.261a2.916 2.916 0 003.65-1.921l.002-.004a2.917 2.917 0 00-1.921-3.65l-16.94-5.261a2.916 2.916 0 00-3.65 1.92l-.001.004a2.917 2.917 0 001.92 3.651zM156.486 163.059a8.225 8.225 0 01-9.19 3.148L28.954 129.455a8.203 8.203 0 01-5.731-8.683l1.238-11.967 140.893 43.755a71.892 71.892 0 00-8.868 10.499z"
        opacity="0.3"
      />
      <path
        fill="#407BFF"
        d="M180.726 132.954c-1.352.054-2.671.603-3.824 1.594-1.154.991-2.102 2.388-2.749 4.052-2.804 4.053-6.382 5.784-10.385 5.711-13.755-.261-28.014-4.59-42.059-4.365-15.884.233-31.99 7.188-47.449 6.279-12.088-.728-24.005-8.236-36.077-10.106-12.369-1.906-25.407-2.343-38.113-3.347 14.95-.727 30.636-2.99 45.112-.793 10.764 1.637 21.71 8.258 32.453 8.847 13.6.728 27.806-7.006 41.701-7.275 12.68-.226 25.739 5.566 38.128 5.551 3.531 0 6.75-1.455 9.263-5.093.564-1.494 1.404-2.749 2.431-3.634 1.027-.886 2.205-1.369 3.411-1.401 11.906-.989 24.337 1.652 36.269 3.085-9.367-.036-18.942-.014-28.112.895z"
      />
      <path
        fill="#fff"
        d="M180.726 132.952c-1.352.054-2.671.603-3.824 1.594-1.154.991-2.102 2.388-2.749 4.052-2.804 4.053-6.382 5.784-10.385 5.712-13.755-.262-28.014-4.591-42.059-4.366-15.884.233-31.99 7.188-47.449 6.279-12.088-.728-24.005-8.236-36.077-10.106-12.369-1.906-25.407-2.343-38.113-3.347 14.95-.727 30.636-2.99 45.112-.793 10.764 1.637 21.71 8.258 32.453 8.847 13.6.728 27.806-7.006 41.701-7.275 12.68-.226 25.739 5.566 38.128 5.551 3.531 0 6.75-1.455 9.263-5.093.564-1.494 1.404-2.749 2.431-3.634 1.027-.886 2.205-1.369 3.411-1.401 11.906-.989 24.337 1.652 36.269 3.085-9.367-.036-18.942-.014-28.112.895z"
        opacity="0.5"
      />
      <path
        fill="#407BFF"
        d="M186.109 115.558c-10.042 0-17.846 6.846-29.254 6.846-11.942 0-22.577-5.391-35.568-5.391-16.273 0-27 5.391-44.915 5.391-16.008 0-25.88-5.355-41.8-5.355-15.057 0-34.529 1.718-34.529 1.718s19.472-5.326 34.53-5.326c15.92 0 25.79 4.627 41.8 4.627 17.913 0 28.641-6.119 44.914-6.119 12.981 0 23.641 6.119 35.568 6.119 11.424 0 19.212-6.119 29.254-6.119 9.69 0 22.702 6.119 22.702 6.119s-13.012-2.51-22.702-2.51z"
      />
      <path
        fill="#327FEB"
        d="M209.999 118.394l-5.748 37.23c-1.34 8.665-6.486 13.991-12.784 14.958-16.912 2.605-50.403 4.366-88.957 4.366-35.828 0-67.253-1.528-85.1-3.835-6.672-.844-12.093-6.766-13.131-16.035L.063 118.765 36.17 114.4l43.616 6.373 41.39-6.061 35.708 7.72 29.758-8.731 23.356 4.693z"
        opacity="0.2"
      />
      <path
        fill="#fff"
        d="M186.109 115.558c-10.042 0-17.846 6.846-29.254 6.846-11.942 0-22.577-5.391-35.568-5.391-16.273 0-27 5.391-44.915 5.391-16.008 0-25.88-5.355-41.8-5.355-15.057 0-34.529 1.718-34.529 1.718s19.472-5.326 34.53-5.326c15.92 0 25.79 4.627 41.8 4.627 17.913 0 28.641-6.119 44.914-6.119 12.981 0 23.641 6.119 35.568 6.119 11.424 0 19.212-6.119 29.254-6.119 9.69 0 22.702 6.119 22.702 6.119s-13.012-2.51-22.702-2.51z"
        opacity="0.5"
      />
      <path
        fill="#fff"
        d="M16 143.432a1.502 1.502 0 01-1.775 1.492A1.5 1.5 0 1116 143.432zM27 154.432a1.502 1.502 0 01-1.775 1.492A1.5 1.5 0 1127 154.432zM14.94 159.288a1.005 1.005 0 01-.893.66.995.995 0 01-.952-.574 1 1 0 111.846-.086zM19.971 147.617a.501.501 0 01-.873.129.503.503 0 01-.026-.555.502.502 0 01.215-.194c.089-.042.189-.057.287-.042a.5.5 0 01.397.662zM37.278 161.779a1.506 1.506 0 011.078 1.271 1.5 1.5 0 11-1.078-1.271zM54.17 167.323a1.005 1.005 0 01.377 1.046 1 1 0 11-.377-1.046zM44.425 159.168a.495.495 0 01.2.329.495.495 0 01-.093.372.494.494 0 01-.771.04.5.5 0 01.664-.741zM191.718 148.546a1.5 1.5 0 11-2.056-2.185 1.5 1.5 0 012.056 2.185zM191.054 164.087a1.5 1.5 0 11-2.056-2.185 1.5 1.5 0 012.056 2.185zM179.323 158.489a.996.996 0 01-1.091-.212.992.992 0 01-.221-1.089 1.005 1.005 0 011.478-.454.999.999 0 01-.166 1.755zM191.321 154.3a.502.502 0 01-.55-.813.504.504 0 01.544-.111.504.504 0 01.311.46.5.5 0 01-.305.464z"
      />
    </svg>
  );
}

export default Icon;
