import * as React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import Zoom from 'react-reveal/Zoom';
import { CSS } from 'styles';

import { BackdropContainer } from './styles';

type Props = {
  cancelable: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  css?: CSS;
};

export default function DialogBackdrop(props: Props) {
  const { children, onClose, cancelable, className, css } = props;

  const onCancel = React.useCallback(() => {
    if (cancelable) {
      onClose();
    }
  }, [cancelable, onClose]);

  return (
    <BackdropContainer className={className} css={css}>
      <Zoom duration={300} big>
        <OutsideClickHandler onOutsideClick={onCancel}>
          {children}
        </OutsideClickHandler>
      </Zoom>
    </BackdropContainer>
  );
}
