module.exports = {
  locales: ['en', 'id'],
  defaultLocale: 'id',
  pages: {
    '*': ['common', 'error'],
    '/check-booking': ['booking', 'shipping'],
    '/shipping/search': ['search'],
    '/shipping/addons': ['shipping'],
    '/shipping/[id]': ['shipping'],
    '/shipping/[id]/payment': ['shipping', 'booking', 'step', 'reschedule'],
    '/check-booking/[id]/refund': ['shipping', 'booking', 'refund'],
    '/check-booking/[id]/reschedule': [
      'shipping',
      'booking',
      'refund',
      'reschedule',
    ],
    '/guest': ['shipping'],
    '/profile': ['booking'],
    '/guest/[id]/vehicle': ['booking', 'shipping'],
    '/guest/[id]/passenger': ['booking', 'shipping'],
    '/guest/add-vehicle': ['booking', 'shipping'],
    '/guest/add-passenger': ['booking', 'shipping'],
  },
  localeDetection: false,
  loader: false,
  loadLocaleFrom: (lang, ns) => require(`./src/locales/${lang}/${ns}`),
};
