import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { MutationFetchFunction } from 'common/helpers/common';
import {
  ApiError,
  ApiResult,
  MessageResult,
} from 'common/repositories/common.model';
import { queryClient } from 'common/repositories/query-client';
import { client } from 'hooks/use-ky';

import {
  ForgotPasswordMutationInput,
  LoginMutationInput,
  ProfileUpdateInput,
  RefreshTokenMutationInput,
  RegisterMutationInput,
  TokenResultModel,
  UserChangePasswordInput,
  UserChangePhoneInput,
  UserModel,
  UserUpdateBankAccountInput,
  UserUpdateEmailInput,
} from './model';
import { getMeKey } from './query';

export const refreshToken = async function (
  refresh_token: string,
): Promise<ApiResult<TokenResultModel>> {
  return await client
    .post('auth/refresh', { json: { refresh_token } })
    .json<ApiResult<TokenResultModel>>();
};

export function useChangePassword(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    UserChangePasswordInput
  >,
) {
  return useMutation<MessageResult, ApiError, UserChangePasswordInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'me/auth/change-password',
        method: 'POST',
        classType: MessageResult,
        body: body.body,
      });
    },
    options,
  );
}

export function useUpdateProfile(
  options?: UseMutationOptions<MessageResult, ApiError, ProfileUpdateInput>,
) {
  return useMutation<MessageResult, ApiError, ProfileUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'me/update-profile',
        method: 'PUT',
        classType: MessageResult,
        body,
      });
    },
    {
      ...options,
      onSuccess(data, variables, context) {
        queryClient.refetchQueries(getMeKey());
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}

export function useRevoke(options?: UseMutationOptions<any, ApiError>) {
  return useMutation<any, ApiError>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'auth/revoke',
        method: 'POST',
        body,
      });
    },
    {
      ...options,
      onSuccess(data, variables, context) {
        queryClient.refetchQueries(getMeKey());
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}

export function useChangePhoneNumber(
  options?: UseMutationOptions<any, ApiError, UserChangePhoneInput>,
) {
  return useMutation<any, ApiError, UserChangePhoneInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'me/update-phone',
        method: 'PATCH',
        body,
      });
    },
    {
      ...options,
      onSuccess(data, variables, context) {
        queryClient.refetchQueries(getMeKey());
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}

export function useUpdateEmail(
  options?: UseMutationOptions<any, ApiError, UserUpdateEmailInput>,
) {
  return useMutation<any, ApiError, UserUpdateEmailInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'auth/update-email',
        method: 'PATCH',
        body,
      });
    },
    {
      ...options,
      onSuccess(data, variables, context) {
        queryClient.refetchQueries(getMeKey());
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}

export function useUpdateBankAccount(
  options?: UseMutationOptions<any, ApiError, UserUpdateBankAccountInput>,
) {
  return useMutation<
    ApiResult<UserModel>,
    ApiError,
    UserUpdateBankAccountInput
  >(
    async function (body) {
      return await MutationFetchFunction({
        url: 'me/update-bank-account',
        method: 'PUT',
        body,
      });
    },
    {
      ...options,
      onSuccess(data, variables, context) {
        queryClient.refetchQueries(getMeKey());
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}

export function useRegister(
  options?: UseMutationOptions<any, ApiError, RegisterMutationInput>,
) {
  return useMutation<any, ApiError, RegisterMutationInput>(async function (
    body,
  ) {
    return await MutationFetchFunction({
      url: 'auth/register',
      method: 'POST',
      body,
    });
  },
  options);
}

export function useForgotPassword(
  options?: UseMutationOptions<any, ApiError, ForgotPasswordMutationInput>,
) {
  return useMutation<any, ApiError, ForgotPasswordMutationInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'auth/reset-password',
        method: 'POST',
        body,
      });
    },
    options,
  );
}

export function useLogin(
  options?: UseMutationOptions<
    ApiResult<TokenResultModel>,
    ApiError,
    LoginMutationInput
  >,
): UseMutationResult<ApiResult<TokenResultModel>, ApiError> {
  return useMutation<ApiResult<TokenResultModel>, ApiError, LoginMutationInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'auth/login',
        method: 'POST',
        classType: TokenResultModel,
        body,
      });
    },
    options,
  );
}

export function useRefreshToken(
  options?: UseMutationOptions<
    ApiResult<TokenResultModel>,
    ApiError,
    RefreshTokenMutationInput
  >,
): UseMutationResult<ApiResult<TokenResultModel>, ApiError> {
  return useMutation<
    ApiResult<TokenResultModel>,
    ApiError,
    RefreshTokenMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: 'auth/refresh',
      method: 'POST',
      classType: TokenResultModel,
      body,
    });
  }, options);
}
