import Button from 'components/elements/button';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import DialogBackdrop from './dialog-backdrop';
import { Panel, Title, Message, ButtonContainer } from './styles';

export interface ConfirmationOption {
  title?: string;
  message: string;
  positiveLabel?: string;
  negativeLabel?: string;
  cancelable?: boolean;
  onPositiveAction: (dismiss: VoidFunction) => void;
  onNegativeAction?: (dismiss: VoidFunction) => void;
  noNegative?: boolean;
}

interface Props extends ConfirmationOption {
  onClose: () => void;
}

export default function ConfirmationDialog(props: Props) {
  const { t } = useTranslation();
  const {
    title,
    message,
    positiveLabel = t('common:sure'),
    negativeLabel = t('common:no'),
    cancelable = true,
    onPositiveAction,
    onNegativeAction,
    noNegative = false,
    onClose,
  } = props;

  const onPositivePress = React.useCallback(() => {
    onPositiveAction(onClose);
  }, [onClose, onPositiveAction]);

  const onNegativePress = React.useCallback(() => {
    if (onNegativeAction) {
      onNegativeAction(onClose);
    } else {
      onClose();
    }
  }, [onClose, onNegativeAction]);

  return (
    <DialogBackdrop onClose={onClose} cancelable={cancelable}>
      <Panel>
        {title && <Title>{title}</Title>}
        <Message>{message}</Message>
        <ButtonContainer>
          <Button type="button" onClick={onPositivePress}>
            {positiveLabel}
          </Button>
          {!noNegative && (
            <Button type="button" variant="secondary" onClick={onNegativePress}>
              {negativeLabel}
            </Button>
          )}
        </ButtonContainer>
      </Panel>
    </DialogBackdrop>
  );
}
