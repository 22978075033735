import React from 'react';

function Icon(props: any) {
  const { size = 64, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <g clipPath="url(#clip0_1777_30875)">
        <path
          fill="#6F797B"
          fillRule="evenodd"
          d="M8.847 40.19a2.613 2.613 0 00.957 3.57l4.982 2.877a8.053 8.053 0 0113.13 7.58l4.982 2.877a2.613 2.613 0 003.57-.956l18.666-32.332a2.613 2.613 0 00-.956-3.57l-4.983-2.877a8.053 8.053 0 01-13.129-7.58l-4.982-2.876a2.613 2.613 0 00-3.57.956L8.847 40.191zm-5.515 3.392a8.053 8.053 0 01.804-6.111L22.803 5.139a8.053 8.053 0 0111-2.947l6.929 4a2.72 2.72 0 01.996 3.715 2.613 2.613 0 104.526 2.614 2.72 2.72 0 013.716-.996l6.928 4a8.053 8.053 0 012.947 11.001L41.18 58.858a8.053 8.053 0 01-11.001 2.947l-6.928-4a2.72 2.72 0 01-.996-3.715 2.614 2.614 0 00-4.526-2.614 2.72 2.72 0 01-3.716.996l-6.928-4a8.054 8.054 0 01-3.752-4.89z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_1777_30875">
          <path fill="#fff" d="M0 0H64V64H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
