import { PageNotFoundSvg } from 'assets/images/svg';
import NavigationEnum from 'common/navigation';
import { AspectRatio } from 'components/commons/aspect-ratio';
import Stack from 'components/commons/stack';
import Text from 'components/commons/text';
import Button from 'components/elements/button';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import typography from 'styles/typography';

import { sytles } from './style';

interface Props {
  reason?: string;
}

export default function PageNotFound(props: Props) {
  const { t } = useTranslation();
  const { reason = t('common:page_not_found') } = props;
  return (
    <Stack.Item
      alignItems="center"
      justify="center"
      css={{
        px: 16,
        py: 32,
        minHeight: 400,
        '& > *:not(:first-child)': {
          mt: 24,
        },
      }}
    >
      <Stack.Item css={sytles.notFoundImageBreakPoints}>
        <AspectRatio ratio={4 / 3}>
          <PageNotFoundSvg size="100%" />
        </AspectRatio>
      </Stack.Item>
      <Text
        align="center"
        css={{ ...typography.headline5, '@sm': typography.headline4 }}
      >
        {reason}
      </Text>

      <Link
        href={NavigationEnum.home}
        passHref
        style={{
          textDecoration: 'none',
        }}
      >
        <Button>{t('common:back_to_home')}</Button>
      </Link>
    </Stack.Item>
  );
}
