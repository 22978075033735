import Text from 'components/commons/text';
import Button from 'components/elements/button';
import useTranslation from 'next-translate/useTranslation';
import buttonSize from 'styles/button-size';
import typography from 'styles/typography';

import { BottomSheetContainer, BottomSheetContent } from './styles';

interface Props {
  onClose?: () => void;
  closeText?: string;
  isOpen: boolean;
  description: string;
}

export default function BottomSheet(props: Props) {
  const { t } = useTranslation();
  const { description, isOpen, onClose, closeText = t('common:ok') } = props;
  return isOpen ? (
    <BottomSheetContainer>
      <BottomSheetContent>
        <Text
          variant="body4"
          align="center"
          css={{ '@md': { ...typography.body2, textAlign: 'justify' } }}
        >
          {description}
        </Text>
        {!!onClose && (
          <Button
            style={{ minWidth: '100px' }}
            variant="secondary"
            onClick={onClose}
            css={{
              ...buttonSize.small,
              minWidth: 'max-content',
              '@md': {
                ...buttonSize.medium,
                minWidth: '100px',
              },
            }}
          >
            {closeText}
          </Button>
        )}
      </BottomSheetContent>
    </BottomSheetContainer>
  ) : null;
}
