import React from 'react';

function Icon(props: any) {
  const { size = 128, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size + 1}
      fill="none"
      viewBox="0 0 128 129"
      {...rest}
    >
      <path
        fill="#C9CCCF"
        fillRule="evenodd"
        d="M26.453 28a5.05 5.05 0 00-5.05 5.05V56.9h71.2V33.05a5.05 5.05 0 00-5.05-5.05h-61.1zm-6.4-1.35a9.05 9.05 0 016.4-2.65h61.1a9.05 9.05 0 019.05 9.05V58.9a2 2 0 01-2 2h-75.2a2 2 0 01-2-2V33.05c0-2.4.954-4.702 2.65-6.4zm-5.35 58.45a2 2 0 012 2v9.4a2 2 0 11-4 0v-9.4a2 2 0 012-2zm84.6 0a2 2 0 012 2v9.4a2 2 0 11-4 0v-9.4a2 2 0 012-2z"
        clipRule="evenodd"
      />
      <path
        fill="#C9CCCF"
        fillRule="evenodd"
        d="M27.1 47.801a9.05 9.05 0 016.4-2.65h14.1a9.05 9.05 0 019.05 9.05v2.7h.7v-2.7a9.05 9.05 0 019.05-9.05h14.1a9.05 9.05 0 019.05 9.05v2.7h7.4a9.049 9.049 0 019.05 9.05V87.1a2 2 0 01-2 2H10a2 2 0 01-2-2V65.95a9.05 9.05 0 019.05-9.05h7.4v-2.7c0-2.4.954-4.703 2.65-6.4zm1.35 9.1h24.2v-2.7a5.05 5.05 0 00-5.05-5.05H33.5a5.05 5.05 0 00-5.05 5.05v2.7zm-11.4 4A5.05 5.05 0 0012 65.95V85.1h90V65.95a5.05 5.05 0 00-5.05-5.05h-79.9zm68.5-4v-2.7a5.05 5.05 0 00-5.05-5.05H66.4a5.05 5.05 0 00-5.05 5.05v2.7h24.2z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M90.257 69.103c-14.885 0-26.951 12.066-26.951 26.951s12.066 26.952 26.951 26.952 26.952-12.067 26.952-26.952c0-3.572-.694-6.975-1.95-10.087a2.994 2.994 0 115.553-2.242 32.851 32.851 0 012.386 12.33c0 18.192-14.748 32.94-32.94 32.94-18.194 0-32.942-14.748-32.942-32.94 0-18.194 14.748-32.942 32.941-32.942 3.138 0 6.178.44 9.06 1.262a2.994 2.994 0 11-1.645 5.76 26.97 26.97 0 00-7.415-1.032zm14.37.502a2.995 2.995 0 014.161-.788 33.104 33.104 0 016.502 5.825 2.995 2.995 0 11-4.55 3.895 27.097 27.097 0 00-5.325-4.771 2.996 2.996 0 01-.788-4.161z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M103.226 83.457a2.994 2.994 0 010 4.235L81.889 109.03a2.996 2.996 0 01-4.235-4.235l21.337-21.337a2.994 2.994 0 014.235 0z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M77.66 83.457a2.995 2.995 0 000 4.235l21.337 21.337a2.995 2.995 0 004.235-4.235L81.896 83.457a2.995 2.995 0 00-4.235 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
