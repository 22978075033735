import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { isAccessTokenExpired } from 'common/credential-storage';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';
import { ApiError, ApiResult } from 'common/repositories/common.model';

import { UserModel } from './model';

export function useGetMe(
  options?: UseQueryOptions<ApiResult<UserModel>, ApiError>,
): UseQueryResult<ApiResult<UserModel>, ApiError> {
  return QueryTransformer(
    useQuery<ApiResult<UserModel>, ApiError>(
      ['getMe'],
      () => QueryFetchFunction({ url: 'me' }),
      {
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: !isAccessTokenExpired(),
        ...options,
      },
    ),
    UserModel,
  );
}

export function getMeKey() {
  return ['getMe'];
}
