import * as React from 'react';
import Fade from 'react-reveal/Fade';
import { styled } from 'styles';

export interface Props {
  onClose: () => void;
  children: React.ReactNode;
  width?: number | string;
}

export function PanelModal(props: Props) {
  const { children, width } = props;

  return (
    <Container
      css={{
        '& > .react-reveal > div': {
          maxWidth: width,
          width: '100%',
        },
      }}
    >
      <Fade duration={300} right>
        <div>{children}</div>
      </Fade>
    </Container>
  );
}

const Container = styled('div', {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: 'rgba(0, 0, 0, 0.25)',
  display: 'flex',
  zIndex: 1000,
  justifyContent: 'flex-end',
  alignItems: 'center',
  // transition: '0.2s all',
  '.react-reveal': {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  '& > .react-reveal > div': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'flex-end',
  },
});
