import React from 'react';

function Icon(props: any) {
  const { size = 24, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size - 8}
      fill="none"
      viewBox="0 0 36 44"
      {...rest}
    >
      <path
        fill="#104FA6"
        d="M18 0L0 8v12c0 11.1 7.68 21.48 18 24 10.32-2.52 18-12.9 18-24V8L18 0zm14 20c0 3.7-1.02 7.3-2.76 10.42l-2.9-2.9a9.988 9.988 0 00-1.28-12.58 10.006 10.006 0 00-14.14 0 10.006 10.006 0 000 14.14 10.012 10.012 0 0012.58 1.28l3.44 3.44c-2.38 2.84-5.46 5.02-8.94 6.08C9.96 37.38 4 29.04 4 20v-9.4l14-6.22 14 6.22V20zm-14 8c-3.32 0-6-2.68-6-6s2.68-6 6-6 6 2.68 6 6-2.68 6-6 6z"
      />
    </svg>
  );
}

export default Icon;
