import React from 'react';

function Icon(props: any) {
  const { size = 64, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size + 6}
      fill="none"
      viewBox="0 0 64 70"
      {...rest}
    >
      <path
        fill="#C9CCCF"
        d="M8.042 41.441h23.745c.098 0 .183.069.204.165l1.298 6.008h-9.382a.208.208 0 00-.173.093l-1.02 1.53a.208.208 0 01-.173.092H13.62a.208.208 0 01-.174-.093l-1.019-1.53a.208.208 0 00-.173-.092h-4.42V41.65c0-.115.094-.209.209-.209zM11.809 34.439a.208.208 0 00-.209-.209H9.73a.208.208 0 00-.208.209v5.507h2.287V34.44zM16.363 34.438a.208.208 0 00-.209-.208h-1.86a.208.208 0 00-.208.209l.008 5.507h2.277l-.008-5.508zM27.637 35.522a.208.208 0 00-.2-.155H19.32a.208.208 0 00-.209.208v4.365h9.698l-1.172-4.418z"
      />
      <path
        fill="#C9CCCF"
        d="M6.915 56.271l-1.524-6.932a.208.208 0 01.203-.253h5.759c.069 0 .134.034.172.092l1.085 1.608a.208.208 0 00.173.092h10.671a.208.208 0 00.162-.076l1.346-1.64a.209.209 0 01.16-.076H36.53c.15 0 .252.156.19.294l-3.143 6.932a.208.208 0 01-.19.123H7.12a.208.208 0 01-.204-.164z"
      />
      <mask
        id="mask0_3609_31809"
        style={{ maskType: 'alpha' }}
        width="55"
        height="54"
        x="7"
        y="5"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#654B08"
          fillRule="evenodd"
          d="M34.158 10.497c-11.916 0-21.575 9.66-21.575 21.575s9.66 21.574 21.575 21.574 21.574-9.659 21.574-21.574a21.543 21.543 0 00-1.586-8.136 2.667 2.667 0 014.94-2.013 26.878 26.878 0 011.98 10.148c0 14.861-12.047 26.909-26.908 26.909S7.25 46.933 7.25 32.072c0-14.86 12.047-26.908 26.908-26.908 2.47 0 4.867.334 7.146.96a2.667 2.667 0 11-1.413 5.143 21.596 21.596 0 00-5.733-.77zm11.432.028a2.667 2.667 0 013.705-.702 27.042 27.042 0 015.31 4.758 2.667 2.667 0 11-4.05 3.468 21.712 21.712 0 00-4.264-3.819 2.667 2.667 0 01-.701-3.705z"
          clipRule="evenodd"
        />
        <path
          fill="#654B08"
          fillRule="evenodd"
          d="M34.428 14.668a2.667 2.667 0 012.667 2.667v14.231h8.23a2.667 2.667 0 110 5.333H31.761V17.335a2.667 2.667 0 012.666-2.667z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_3609_31809)">
        <path
          fill="#C9CCCF"
          d="M28.356 30.714H6.539V5.137h54.542V57.51h-18.18L28.356 30.714z"
        />
      </g>
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M40.127 39.553c-7.443 0-13.476 6.034-13.476 13.476 0 7.442 6.033 13.476 13.476 13.476 7.442 0 13.475-6.033 13.475-13.476 0-1.786-.346-3.488-.975-5.043a1.497 1.497 0 012.777-1.122 16.428 16.428 0 011.193 6.165c0 9.096-7.374 16.47-16.47 16.47-9.097 0-16.47-7.374-16.47-16.47s7.373-16.47 16.47-16.47c1.568 0 3.088.22 4.53.63a1.497 1.497 0 01-.823 2.88 13.487 13.487 0 00-3.707-.516zm7.185.251a1.497 1.497 0 012.08-.394 16.553 16.553 0 013.251 2.913 1.497 1.497 0 01-2.275 1.947 13.559 13.559 0 00-2.662-2.385 1.497 1.497 0 01-.394-2.08z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M46.611 46.728a1.497 1.497 0 010 2.117L35.943 59.514a1.497 1.497 0 01-2.118-2.118l10.669-10.668a1.497 1.497 0 012.117 0z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M33.83 46.728a1.497 1.497 0 000 2.117L44.5 59.514a1.497 1.497 0 002.117-2.118L35.948 46.728a1.497 1.497 0 00-2.118 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
