import React from 'react';

function Icon(props: any) {
  const { size = 64, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        stroke="#C9CCCF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.529"
        d="M43 26.907a14.939 14.939 0 01-4.406 10.703c-5.766 5.765-15.203 5.828-21.047.14A15 15 0 0128.094 12a1.265 1.265 0 011.218 1.531 5 5 0 004.813 6.094 1.25 1.25 0 011.25 1.25 5 5 0 006.094 4.813A1.265 1.265 0 0143 26.907z"
      />
      <path
        fill="#C9CCCF"
        d="M32.375 35.75a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zM22.375 34.5a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zM21.125 25.75a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zM29.25 28.25a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75z"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M40.998 35.098c-5.467 0-9.9 4.433-9.9 9.9 0 5.468 4.433 9.9 9.9 9.9 5.468 0 9.9-4.432 9.9-9.9a9.87 9.87 0 00-.716-3.705 1.1 1.1 0 112.04-.824c.566 1.4.876 2.93.876 4.53 0 6.682-5.417 12.1-12.1 12.1-6.682 0-12.1-5.418-12.1-12.1 0-6.683 5.418-12.1 12.1-12.1 1.153 0 2.27.16 3.328.463a1.1 1.1 0 01-.604 2.115 9.91 9.91 0 00-2.724-.379zm5.279.185a1.1 1.1 0 011.528-.29 12.16 12.16 0 012.389 2.14 1.1 1.1 0 11-1.672 1.43 9.961 9.961 0 00-1.956-1.752 1.1 1.1 0 01-.289-1.528z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M45.762 40.373a1.1 1.1 0 010 1.556l-7.837 7.837a1.1 1.1 0 11-1.556-1.555l7.838-7.838a1.1 1.1 0 011.555 0z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M36.378 40.373a1.1 1.1 0 000 1.556l7.838 7.837a1.1 1.1 0 001.556-1.555l-7.838-7.838a1.1 1.1 0 00-1.556 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
