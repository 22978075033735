import Link from 'next/link';
import * as React from 'react';
import { CSS, styled, VariantProps } from 'styles';
import typography from 'styles/typography';

const StyledActionButton = styled(Link, {
  textDecoration: 'none',
  color: '$primary2',
  padding: 8,
  height: 'fit-content',
  cursor: 'pointer',
  ...typography.buttonSmall,
  '&:disabled': {
    cursor: 'not-allowed',
  },
  '&:hover': {
    opacity: 0.5,
  },
  variants: {
    isActive: {
      true: {
        borderBottomColor: '$primary2',
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
      },
    },
  },
  '@xl': {
    ...typography.buttonMedium,
  },
});

type NavHeaderButtonProps = React.ComponentProps<typeof Link>;
type CssProps = { css?: CSS };
type NavHeaderButtonVariants = VariantProps<typeof StyledActionButton>;

export type INavHeaderButtonProps = NavHeaderButtonProps &
  CssProps &
  NavHeaderButtonVariants;

const NavHeaderButton = React.forwardRef<
  HTMLAnchorElement,
  INavHeaderButtonProps
>((props, ref) => <StyledActionButton {...props} ref={ref} />);

NavHeaderButton.displayName = 'NavHeaderButton';

export default NavHeaderButton;
