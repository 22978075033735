import { Expose, Type } from 'class-transformer';

export class UserModel {
  id: string;
  name: string;
  username: string;
  email: string;
  gender: string;

  @Type(() => Date)
  birthdate: Date | null;

  @Expose({ name: 'domicile_city' })
  domicileCity: string;

  @Expose({ name: 'domicile_city_address' })
  domicileCityAddress: string;

  @Expose({ name: 'identifier_code' })
  identifierCode: string;

  @Expose({ name: 'identifier_type' })
  identifierType: string;

  @Expose({ name: 'phone_number' })
  phoneNumber: string;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;

  @Expose({ name: 'deleted_at' })
  @Type(() => Date)
  deletedAt: Date;

  @Expose({ name: 'bank_account_name' })
  bankAccountName: string | null;

  @Expose({ name: 'bank_account_number' })
  bankAccountNumber: string | null;

  @Expose({ name: 'bank_name' })
  bankName: string | null;
}

export class TokenResultModel {
  @Expose({ name: 'token_type' })
  tokenType: string;

  @Expose({ name: 'expires_in' })
  expiresIn: number;

  @Expose({ name: 'access_token' })
  accessToken: string;

  @Expose({ name: 'refresh_token' })
  refreshToken: string;
}

export class UserChangePasswordInput {
  body: UserChangePasswordFormInput;
}

export class UserChangePasswordFormInput {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}

export class UserChangePhoneInput {
  phoneNumber: string;
}

export class UserUpdateEmailInput {
  email: string;
  verificationToken: string;
}

export class UserUpdateBankAccountInput {
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
}

export class RegisterMutationInput {
  email: string;
  name: string;
  verificationToken: string;
  gender: string;
  // birthdate: string;
  identifierCode: string;
  identifierType: string;
  // domicileCity: string;
  // domicileCityAddress: string;
  password: string;
  passwordConfirmation: string;
}

export class ForgotPasswordMutationInput {
  email: string;
  verificationToken: string;
  password: string;
}

export class ProfileUpdateInput {
  name: string;
  // email: string;
  // password: string;
  gender: string;
  identifierType: string;
  identifierCode: string;
  birthdate?: string | null;
  domicileCity?: string | null;
  domicileCityAddress?: string | null;
}

export class LoginMutationInput {
  username: string;
  password: string;
}

export class RefreshTokenMutationInput {
  refreshToken: string;
}
