import React from 'react';

function Icon(props: any) {
  const { size = 111, color = '#327FEB', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size - 1}
      fill="none"
      viewBox="0 0 110 110"
      {...rest}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M17.192 47.994a5.376 5.376 0 015.235-4.15h63.876a5.376 5.376 0 015.376 5.376v14.556h-4.135V49.22a1.24 1.24 0 00-1.241-1.24H22.427a1.24 1.24 0 00-1.208.957l-3.263 13.936-4.026-.943 3.262-13.936z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M76.094 28.376A5.376 5.376 0 0181.47 23h.4a5.376 5.376 0 015.375 5.376v19.601H76.094V28.376zm5.376-1.24a1.24 1.24 0 00-1.24 1.24v15.466h2.88V28.376a1.24 1.24 0 00-1.24-1.24h-.4zM61.945 28.376A5.376 5.376 0 0167.321 23h.4a5.376 5.376 0 015.376 5.376v19.601H61.945V28.376zm5.376-1.24a1.24 1.24 0 00-1.24 1.24v15.466h2.88V28.376a1.24 1.24 0 00-1.24-1.24h-.4zM29.769 35.413a5.376 5.376 0 015.17-3.905h18.75a5.376 5.376 0 015.376 5.376v11.091h-32.87l3.574-12.562zm5.17.23a1.24 1.24 0 00-1.193.901l-2.075 7.296H54.93v-6.956a1.24 1.24 0 00-1.241-1.24h-18.75zM5.88 67.448c-1.604-3.557.998-7.585 4.9-7.585h27.544c1.61 0 3.134.721 4.155 1.965l2.273 2.768c.236.287.587.453.959.453h28.165a1.24 1.24 0 001.029-.546l1.531-2.27c1-1.482 2.67-2.37 4.457-2.37h11.861c3.434 0 5.988 3.177 5.25 6.531l-3.793 17.248-4.039-.888 3.794-17.248a1.24 1.24 0 00-1.212-1.507H80.893a1.24 1.24 0 00-1.029.546l-1.531 2.27-1.698-1.145 1.698 1.145a5.376 5.376 0 01-4.457 2.37H45.711c-1.61 0-3.134-.72-4.155-1.965l-2.273-2.768a1.24 1.24 0 00-.959-.453H10.781c-.901 0-1.502.929-1.132 1.75l5.885 13.056-3.77 1.7L5.88 67.447z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M78.042 83.6a16.177 16.177 0 00-11.77-.266l-1.342.49a23.967 23.967 0 01-16.429 0l-.531-.195a19.83 19.83 0 00-13.594 0l-1.903.695a18.783 18.783 0 01-13.667-.31 14.648 14.648 0 00-12.884.79L3.09 86.417a2.068 2.068 0 11-2.046-3.593l2.83-1.612a18.783 18.783 0 0116.523-1.013 14.648 14.648 0 0010.658.24l1.902-.693a23.965 23.965 0 0116.43 0l.53.194a19.83 19.83 0 0013.595 0l1.341-.49a20.312 20.312 0 0114.78.334l1.074.448a13.385 13.385 0 0010.303 0 17.52 17.52 0 0116.489 1.611l1.582 1.06a2.067 2.067 0 11-2.3 3.436l-1.582-1.06a13.385 13.385 0 00-12.597-1.23 17.522 17.522 0 01-13.487 0l-1.074-.448z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
