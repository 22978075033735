import React from 'react';

function Icon(props: any) {
  const { size = '24', color = '#F10C0C', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M22 6H4M9 2h8M20 6v15.2a.812.812 0 01-.228.566.767.767 0 01-.55.234H6.778a.767.767 0 01-.55-.234A.812.812 0 016 21.2V6"
      />
    </svg>
  );
}

export default Icon;
