import React from 'react';

function Icon(props: any) {
  const { size = 64, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        fill="#654B08"
        d="M8.042 41.441h23.745c.098 0 .183.069.204.165l1.298 6.008h-9.382a.208.208 0 00-.173.093l-1.02 1.53a.208.208 0 01-.173.092H13.62a.208.208 0 01-.174-.093l-1.019-1.53a.208.208 0 00-.173-.092h-4.42V41.65c0-.115.094-.209.209-.209zM11.813 34.439a.208.208 0 00-.209-.209h-1.87a.208.208 0 00-.208.209v5.507h2.287V34.44zM16.366 34.438a.208.208 0 00-.208-.208h-1.86a.208.208 0 00-.208.209l.008 5.507h2.277l-.009-5.508zM27.641 35.522a.208.208 0 00-.2-.155h-8.118a.208.208 0 00-.208.208v4.365h9.698l-1.172-4.418z"
      />
      <path
        fill="#654B08"
        d="M6.915 56.271l-1.524-6.932a.208.208 0 01.203-.253h5.759c.069 0 .134.034.172.092l1.085 1.608a.208.208 0 00.173.092h10.671a.208.208 0 00.162-.076l1.346-1.64a.209.209 0 01.16-.076H36.53c.15 0 .252.156.19.294l-3.143 6.932a.208.208 0 01-.19.123H7.12a.208.208 0 01-.204-.164z"
      />
      <mask
        id="mask0_3952_32516"
        style={{ maskType: 'alpha' }}
        width="55"
        height="54"
        x="7"
        y="5"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#654B08"
          fillRule="evenodd"
          d="M34.166 10.497c-11.916 0-21.575 9.66-21.575 21.575s9.66 21.574 21.575 21.574S55.74 43.987 55.74 32.072a21.546 21.546 0 00-1.586-8.136 2.667 2.667 0 014.94-2.013 26.878 26.878 0 011.98 10.148c0 14.861-12.048 26.909-26.908 26.909-14.861 0-26.908-12.047-26.908-26.908 0-14.86 12.047-26.908 26.908-26.908 2.47 0 4.867.334 7.146.96a2.667 2.667 0 01-1.414 5.143 21.597 21.597 0 00-5.732-.77zm11.432.028a2.667 2.667 0 013.705-.702 27.042 27.042 0 015.31 4.758 2.667 2.667 0 11-4.05 3.468 21.716 21.716 0 00-4.264-3.819 2.667 2.667 0 01-.701-3.705z"
          clipRule="evenodd"
        />
        <path
          fill="#654B08"
          fillRule="evenodd"
          d="M34.432 14.668a2.667 2.667 0 012.667 2.667v14.231h8.23a2.667 2.667 0 110 5.333H31.765V17.335a2.667 2.667 0 012.666-2.667z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_3952_32516)">
        <path
          fill="#654B08"
          d="M28.356 30.714H6.539V5.137h54.542V57.51h-18.18L28.356 30.714z"
        />
      </g>
    </svg>
  );
}

export default Icon;
