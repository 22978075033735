import React from 'react';

function Icon(props: any) {
  const { size = 24, color = '#104FA6', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        fill={color}
        d="M6 56.5c-.53 0-1.04.184-1.414.513A1.647 1.647 0 004 58.25c0 .464.21.91.586 1.237C4.96 59.816 5.47 60 6 60h52c.53 0 1.04-.184 1.414-.513.375-.328.586-.773.586-1.237 0-.464-.21-.91-.586-1.237A2.154 2.154 0 0058 56.5h-6V12.75c0-1.393-.632-2.728-1.757-3.713C49.117 8.053 47.59 7.5 46 7.5h-2V5.75c0-.25-.062-.498-.18-.726a1.792 1.792 0 00-.509-.595 2.1 2.1 0 00-.744-.357 2.275 2.275 0 00-.847-.055l-28 3.5a2.095 2.095 0 00-1.23.585c-.316.319-.49.726-.49 1.148V56.5H6zM44 11h2c.53 0 1.04.184 1.414.512.375.329.586.774.586 1.238V56.5h-4V11zM34 39c-1.104 0-2-1.568-2-3.5s.896-3.5 2-3.5 2 1.568 2 3.5-.896 3.5-2 3.5z"
      />
    </svg>
  );
}

export default Icon;
