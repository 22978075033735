import * as React from 'react';
import { styled, CSS, VariantProps } from 'styles';
import typography from 'styles/typography';

const DEFAULT_TAG = 'span';

const StyledText = styled(DEFAULT_TAG, {
  // Reset
  lineHeight: '1',
  margin: '0',
  fontWeight: '$regular',
  fontVariantNumeric: 'tabular-nums',
  display: 'block',
  color: '$defaultFont',

  variants: {
    align: {
      center: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
      },
      right: {
        textAlign: 'right',
      },
    },
    size: {
      xxs: {
        fontSize: '$xxs',
      },
      xs: {
        fontSize: '$xs',
      },
      sm: {
        fontSize: '$sm',
      },
      md: {
        fontSize: '$md',
      },
      lg: {
        fontSize: '$lg',
      },
      xl: {
        fontSize: '$xl',
      },
      xxl: {
        fontSize: '$xxl',
      },
    },
    variant: {
      ...typography,
      // headline1: typography.headline1,
      // headline2: typography.headline2,
      // headline3: typography.headline3,
      // headline4: typography.headline4,
      // headline5: typography.headline5,
      // headline6: typography.headline6,
      // headline7: typography.headline7,

      // body1: typography.body1,
      // body2: typography.body2,
      // body3: typography.body3,
      // body4: typography.body4,
      // body5: typography.body5,
      // body6: typography.body6,

      // buttonExtraSmall: typography.buttonExtraSmall,
      // buttonSmall: typography.buttonSmall,
      // buttonMedium: typography.buttonMedium,
      // buttonNormal: typography.buttonNormal,
      // buttonLarge: typography.buttonLarge,

      noVariant: {},
    },
    weight: {
      regular: {
        fontWeight: '$regular',
      },
      bold: {
        fontWeight: '$bold',
      },
    },
  },
  defaultVariants: {
    variant: 'body',
  },
});

type TextProps = React.HTMLAttributes<HTMLSpanElement>;
type TextCSSProp = { css?: CSS };
type TextVariants = VariantProps<typeof StyledText>;
export type ITextProps = TextProps & TextCSSProp & TextVariants;

const Text = React.forwardRef<HTMLSpanElement, ITextProps>((props, ref) => {
  return <StyledText {...props} ref={ref} />;
});

Text.displayName = 'Text';

export default Text;
