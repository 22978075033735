import React from 'react';

function Icon(props: any) {
  const { size = 24, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 512 512"
      {...rest}
    >
      <g clipPath="url(#clip0_1962_32464)">
        <path
          fill="#C8414B"
          d="M473.655 0H38.345C17.167 0 0 26.202 0 58.527V256h512V58.527C512 26.202 494.833 0 473.655 0z"
        />
        <path
          fill="#F5F5F5"
          d="M0 453.474c0 32.323 17.167 58.527 38.345 58.527h435.31c21.177 0 38.345-26.202 38.345-58.527V256H0v197.474z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1962_32464">
          <rect width="512" height="512" fill="#fff" rx="256" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
