import { styled } from 'styles';

export const Panel = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  borderRadius: '5px',
  minHeight: '200px',
  padding: '1rem',
});

export const Title = styled('h2', {
  margin: '0',
  marginBottom: '0.5rem',
  overflowWrap: 'break-word',
});

export const Message = styled('p', {
  fontSize: '1rem',
  marginBottom: '1rem',
  flexGrow: '1',
  overflowWrap: 'break-word',
});

export const ButtonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'flex-end',
  marginTop: '1rem',
  justifyContent: 'flex-end',
  '& > :nth-child(n + 1)': {
    marginLeft: 10,
  },
});

export const BackdropContainer = styled('div', {
  position: 'fixed',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
  background: 'rgba(0, 0, 0, 0.25)',
  display: 'flex',
  zIndex: '1000',
  justifyContent: 'center',
  alignItems: 'center',
  px: 16,
  '& > .react-reveal': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      width: '100%',
      '@md': {
        maxWidth: 700,
      },
    },
  },
});
