import IconBox from 'components/commons/icon-box';
import Text from 'components/commons/text';
import * as React from 'react';
import { X } from 'react-feather';
import { CSS, VariantProps, styled } from 'styles';
import color from 'styles/color';
import typography from 'styles/typography';

type CSSProp = { css?: CSS };

export const StyledPanelHeader = styled('div', {
  padding: 16,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '$primary',
  alignItems: 'center',
});

export const StyledPanelSide = styled('div', {
  backgroundColor: '$white',
  height: '100%',
  justifyContent: 'flex-start',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const StyledPanelContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
});

export const StyledPanelInnerContent = styled('div', {
  flex: 1,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const StyledPanelFooter = styled('div', {
  display: 'flex',
  width: '100%',
  py: 8,
  px: 24,
});

type PanelHeaderVariants = VariantProps<typeof StyledPanelHeader>;
export type IPanelHeaderProps = CSSProp &
  PanelHeaderVariants &
  React.HTMLAttributes<HTMLDivElement>;

export const PanelHeader = React.forwardRef<HTMLDivElement, IPanelHeaderProps>(
  (props, ref) => <StyledPanelHeader {...props} ref={ref} />,
);

type PanelInnerContentVariants = VariantProps<typeof StyledPanelInnerContent>;
export type IPanelInnerContentProps = CSSProp &
  PanelInnerContentVariants &
  React.HTMLAttributes<HTMLDivElement>;

export const PanelInnerContent = React.forwardRef<
  HTMLDivElement,
  IPanelInnerContentProps
>((props, ref) => <StyledPanelInnerContent {...props} ref={ref} />);

type PanelFooterVariants = VariantProps<typeof StyledPanelFooter>;
export type IPanelFooterProps = CSSProp &
  PanelFooterVariants &
  React.HTMLAttributes<HTMLDivElement>;

export const PanelFooter = React.forwardRef<HTMLDivElement, IPanelFooterProps>(
  (props, ref) => <StyledPanelFooter {...props} ref={ref} />,
);

type PanelContentVariants = VariantProps<typeof StyledPanelContent>;

export type IPanelContentProps = CSSProp &
  PanelContentVariants &
  React.HTMLAttributes<HTMLDivElement>;

export const PanelContent = React.forwardRef<
  HTMLDivElement,
  IPanelContentProps
>((props, ref) => {
  const { ...rest } = props;
  return (
    <StyledPanelContent {...rest} ref={ref}>
      <PanelInnerContent>{props.children}</PanelInnerContent>
    </StyledPanelContent>
  );
});

export const PanelTitleText = styled(Text, {
  color: '$white',
  userSelect: 'none',
  ...typography.headline5,
  '@md': {
    ...typography.headline4,
  },
});

type PanelSideVariants = VariantProps<typeof StyledPanelSide>;

interface PanelSideProps {
  onClose?: () => void;
  closeIcon?: React.ReactNode;
  title?: string;
  onRenderFooterContent?: React.ReactNode;
  width?: string | number;
  contentCss?: CSS;
  footerCss?: CSS;
  contentClassName?: string;
  footerClassName?: string;
  headerClassName?: string;
  headerCss?: CSS;
  css?: CSS;
}

export type IPanelSideProps = CSSProp &
  PanelSideVariants &
  PanelSideProps &
  React.HTMLAttributes<HTMLDivElement>;

export const PanelSide = React.forwardRef<HTMLDivElement, IPanelSideProps>(
  (props, ref) => {
    const {
      onRenderFooterContent,
      title,
      onClose,
      closeIcon = <X color={color.white} />,
      width,
      contentClassName,
      contentCss,
      footerClassName,
      footerCss,
      headerClassName,
      headerCss,
      ...rest
    } = props;
    const [contentHeight, setContentHeight] = React.useState(100);
    const headerRef = React.useRef<any>();
    const footerRef = React.useRef<any>();
    React.useEffect(() => {
      if (window) {
        let tempHeight = window.innerHeight;
        if (headerRef.current) {
          tempHeight -= headerRef.current.offsetHeight;
        }
        if (footerRef.current) {
          tempHeight -= footerRef.current.offsetHeight;
        }
        setContentHeight(tempHeight);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerRef.current, footerRef.current]);

    return (
      <StyledPanelSide {...rest} ref={ref}>
        <PanelHeader
          ref={headerRef}
          css={headerCss}
          className={headerClassName}
        >
          <IconBox css={{ width: 24 }} />
          <PanelTitleText>{title}</PanelTitleText>
          <IconBox onClick={onClose}>{closeIcon}</IconBox>
        </PanelHeader>
        <PanelContent
          className={contentClassName}
          css={{ ...contentCss, maxHeight: contentHeight }}
        >
          {props.children}
        </PanelContent>
        {onRenderFooterContent && (
          <PanelFooter
            ref={footerRef}
            className={footerClassName}
            css={footerCss}
          >
            {onRenderFooterContent}
          </PanelFooter>
        )}
      </StyledPanelSide>
    );
  },
);
