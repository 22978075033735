import React from 'react';

function Icon(props: any) {
  const { size = 73, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size - 1}
      fill="none"
      viewBox="0 0 73 72"
      {...rest}
    >
      <g filter="url(#filter0_d_1962_32451)">
        <rect
          width="64"
          height="64"
          x="4.703"
          y="4"
          fill="#104FA6"
          rx="32"
          shapeRendering="crispEdges"
        />
        <ellipse cx="36.798" cy="28.4" fill="#fff" rx="10.4" ry="10.4" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M18.79 54.527C21.5 47.213 28.54 42 36.797 42c8.257 0 15.297 5.213 18.009 12.527a25.124 25.124 0 01-18.01 7.573c-7.055 0-13.433-2.9-18.008-7.573z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1962_32451"
          width="72"
          height="72"
          x="0.703"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1962_32451"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1962_32451"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
