import React from 'react';

function Icon(props: any) {
  const { size = 24, color = '#8F9596', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <g clipPath="url(#clip0_2313_32143)">
        <path
          fill={color}
          d="M2.25 21.188c-.199 0-.39.069-.53.192a.618.618 0 00-.22.464c0 .174.079.34.22.464.14.123.331.192.53.192h19.5c.199 0 .39-.07.53-.192.141-.123.22-.29.22-.464a.618.618 0 00-.22-.464.807.807 0 00-.53-.192H19.5V4.78c0-.522-.237-1.023-.659-1.392a2.423 2.423 0 00-1.591-.577h-.75v-.656a.588.588 0 00-.068-.272.673.673 0 00-.19-.223.787.787 0 00-.28-.134.853.853 0 00-.317-.02l-10.5 1.312a.785.785 0 00-.461.22.61.61 0 00-.184.43v17.718H2.25zM16.5 4.125h.75c.199 0 .39.069.53.192.141.123.22.29.22.464v16.407h-1.5V4.125zm-3.75 10.5c-.414 0-.75-.588-.75-1.313 0-.724.336-1.312.75-1.312s.75.588.75 1.313c0 .724-.336 1.312-.75 1.312z"
        />
        <path
          fill={color}
          d="M18.317 2.456a2 2 0 012.913 2.741L4.289 23.2a2 2 0 11-2.913-2.741l16.94-18.002z"
        />
        <path
          fill="#F8F9F9"
          fillRule="evenodd"
          d="M3.56 22.514L20.501 4.512a1 1 0 00-1.456-1.37L2.104 21.143a1 1 0 001.456 1.371zM21.144 2.371a2 2 0 00-2.827.085L1.376 20.458A2 2 0 104.289 23.2l16.94-18.001a2 2 0 00-.085-2.827z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_2313_32143">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
