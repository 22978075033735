import React from 'react';

function Icon(props: any) {
  const { size = 64, color = '#104FA6', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        fill={color}
        d="M53.334 29.33h5.333c0-13.68-10.328-23.998-24.026-23.998v5.333c10.832 0 18.693 7.848 18.693 18.664z"
      />
      <path
        fill={color}
        d="M34.667 21.335c5.608 0 8 2.392 8 8h5.334c0-8.6-4.734-13.333-13.334-13.333v5.333zm9.126 14.515a2.67 2.67 0 00-3.71.115l-6.38 6.562c-1.537-.293-4.625-1.256-7.804-4.426-3.178-3.182-4.14-6.278-4.426-7.803l6.557-6.384a2.666 2.666 0 00.115-3.71L18.29 9.37a2.667 2.667 0 00-3.709-.232L8.795 14.1a2.667 2.667 0 00-.773 1.73c-.04.667-.803 16.459 11.443 28.71C30.147 55.22 43.529 56 47.215 56c.538 0 .868-.015.956-.02a2.645 2.645 0 001.728-.776l4.96-5.79a2.666 2.666 0 00-.229-3.71L43.793 35.85z"
      />
    </svg>
  );
}

export default Icon;
