import * as React from 'react';

function Icon(props: any) {
  const { size = 48, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M23.378.945a2.933 2.933 0 011.248 3.956l-.138.266c2.398-.64 4.915-.98 7.51-.98 16.227 0 29.333 13.28 29.333 29.6s-13.106 29.6-29.334 29.6-29.333-13.28-29.333-29.6c0-5.38 1.426-10.435 3.92-14.79a2.933 2.933 0 015.09 2.915 23.817 23.817 0 00-3.143 11.875c0 13.136 10.534 23.734 23.466 23.734 12.933 0 23.467-10.598 23.467-23.734 0-13.135-10.534-23.733-23.467-23.733a23.29 23.29 0 00-5.391.629l.977.302a2.933 2.933 0 01-1.731 5.605l-11.994-3.704 5.564-10.693A2.933 2.933 0 0123.378.945z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        d="M18.21 41.52V24.133h6.268c1.226 0 2.346.169 3.36.507 1.013.32 1.822.87 2.426 1.653.605.782.907 1.867.907 3.253 0 1.28-.284 2.338-.853 3.173-.552.818-1.298 1.432-2.24 1.84l3.946 6.96h-4.4l-3.333-6.266h-2.16v6.267h-3.92zm3.92-9.387h2.028c1.03 0 1.813-.214 2.346-.64.551-.445.827-1.093.827-1.947 0-.853-.276-1.449-.827-1.787-.533-.337-1.315-.506-2.346-.506H22.13v4.88zM34.245 46.426V28.293h3.2l.267 1.306h.106a7.163 7.163 0 011.734-1.146 4.583 4.583 0 012.026-.48c1.6 0 2.863.613 3.787 1.84.925 1.209 1.387 2.835 1.387 4.88 0 1.51-.267 2.8-.8 3.867-.534 1.066-1.227 1.884-2.08 2.453-.836.55-1.734.827-2.694.827-.569 0-1.12-.116-1.653-.347a5.523 5.523 0 01-1.493-1.04l.133 2.106v3.867h-3.92zm6.027-7.787c.675 0 1.253-.31 1.733-.933.48-.622.72-1.609.72-2.96 0-2.382-.764-3.573-2.293-3.573-.765 0-1.52.4-2.267 1.2v5.413c.356.32.711.542 1.067.667.355.124.702.186 1.04.186z"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M14.398 51.71l33.08-37.314 3.99 3.538-33.08 37.314-3.99-3.537z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
