import * as DateFNS from 'date-fns';
import { enUS, id } from 'date-fns/locale';
import * as DateFNSwithTZ from 'date-fns-timezone';

let locale = enUS;
export function updateDateLocale(lang) {
  switch (lang) {
    case 'id':
      locale = id;
      break;
    case 'en':
    default:
      locale = enUS;
      break;
  }
}

export function parseISO(dateString: string | Date): Date {
  if (dateString instanceof Date) {
    return dateString;
  }
  return DateFNS.parseISO(dateString);
}

export function parseFromTimeZone(dateString: string, timeZone: string) {
  return DateFNSwithTZ.parseFromTimeZone(dateString, { timeZone });
}

export function format(date: Date | string, dateFormat: string): string {
  if (typeof date === 'string') {
    date = parseISO(date);
  }
  return DateFNS.format(date, dateFormat, {
    locale,
  });
}

export function distanceToNow(date: Date | string, locale = 'en'): string {
  if (typeof date === 'string') {
    date = parseISO(date);
  }
  return DateFNS.formatDistanceToNow(date, {
    locale: locale === 'en' ? enUS : id,
    addSuffix: true,
  });
}

export const differenceInDays = DateFNS.differenceInDays;

export function pad(n: number) {
  return n < 10 ? '0' + n : n;
}

export function convertTime(minute: number) {
  let hour: string | number = Math.floor(minute / 60);
  hour = pad(hour);
  let minutes: string | number = minute % 60;
  minutes = pad(minutes);

  return hour.toString() + ':' + minutes.toString();
}

export function getFormatTimezone(date: string | Date, targetTz: number) {
  const initialDate = typeof date === 'string' ? new Date(date) : date;
  const targetTimezone = targetTz;
  const tzDifference = targetTimezone * 60 + initialDate.getTimezoneOffset();
  const offsetTime = new Date(initialDate.getTime() + tzDifference * 60 * 1000);
  return {
    offsetTime,
    targetTimezone:
      targetTimezone >= 0 ? `GMT+${targetTimezone}` : `GMT-${targetTimezone}`,
  };
}
