import React from 'react';

function Icon(props: any) {
  const { size = 24, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 22 22"
      {...rest}
    >
      <path
        stroke="#104FA6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4.831 9.625a6.17 6.17 0 1112.34 0v0c0 3.077.645 4.864 1.213 5.844a.687.687 0 01-.593 1.031H4.212a.687.687 0 01-.593-1.03c.568-.98 1.212-2.768 1.212-5.845zM8.25 16.5v.688a2.75 2.75 0 005.5 0V16.5M15.762 2.063a9.023 9.023 0 013.265 3.617M2.973 5.68a9.023 9.023 0 013.265-3.617"
      />
    </svg>
  );
}

export default Icon;
