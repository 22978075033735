import React from 'react';

function Icon(props: any) {
  const { size = 24, color = '#104FA6', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill={color}
        d="M6.4 6C5.635 6 5 6.68 5 7.5v9c0 .82.635 1.5 1.4 1.5h11.2c.765 0 1.4-.68 1.4-1.5v-9c0-.82-.635-1.5-1.4-1.5H6.4zm0 1.5h11.2v9H6.4v-9zm7.35 3.75l-2.45 3-1.75-1.875L7.644 15h8.731l-2.625-3.75z"
      />
    </svg>
  );
}

export default Icon;
