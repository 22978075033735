import React from 'react';

function Icon(props: any) {
  const { size = 64, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        fill="#104FA6"
        d="M8.35 21h42.963a.35.35 0 01.34.27L54 31.173H37.022a.35.35 0 00-.282.143l-1.856 2.54A.35.35 0 0134.6 34h-16.17a.35.35 0 01-.283-.144l-1.856-2.539a.35.35 0 00-.283-.143H8V21.35a.35.35 0 01.35-.35zM15 8.35a.35.35 0 00-.35-.35h-3.3a.35.35 0 00-.35.35V18h4V8.35zM22.985 8.35a.35.35 0 00-.35-.35H19.35a.35.35 0 00-.35.35l.015 9.65H23l-.015-9.65zM43.822 10.255a.35.35 0 00-.337-.255H28.35a.35.35 0 00-.35.35V18h18l-2.178-7.745z"
      />
      <path
        fill="#104FA6"
        d="M3.098 34.428l2.814 12.3a.35.35 0 00.341.272h48.395a.35.35 0 00.316-.2l2.97-6.3 2.83-6a.35.35 0 00-.316-.5H39.396a.35.35 0 00-.266.123l-2.499 2.925a.35.35 0 01-.266.122h-19.68a.35.35 0 01-.287-.149l-2.016-2.872a.35.35 0 00-.286-.149H3.439a.35.35 0 00-.341.428z"
      />
      <path fill="#fff" d="M62 39H41.286L28.5 56H62V39z" />
      <path
        stroke="#104FA6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M43 48l14.44.176M52.828 43.773l4.61 4.403-4.61 4.402"
      />
    </svg>
  );
}

export default Icon;
