import * as React from 'react';
import { CSS, styled, VariantProps } from 'styles';

const DEFAULT_TAG = 'div';

const StyledAspectRatio = styled(DEFAULT_TAG, {
  display: 'flex',
  position: 'relative',
  width: '100%',
});

const StyledContent = styled(DEFAULT_TAG, {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

interface AspectRatioProps extends React.HTMLAttributes<HTMLDivElement> {
  ratio?: number;
  children: React.ReactNode;
}

type AspectRatioCSSProps = { css?: CSS };
type InnerCssProp = { innerCss?: CSS };

type AspectRatioVariants = VariantProps<typeof StyledAspectRatio>;

export type IAspectRatioOwnProps = AspectRatioCSSProps &
  AspectRatioVariants &
  AspectRatioProps &
  InnerCssProp;

export const AspectRatio = React.forwardRef<
  HTMLDivElement,
  IAspectRatioOwnProps
>((props, ref) => {
  const { children, ratio = 1, innerCss, ...restProps } = props;
  return (
    <StyledAspectRatio css={{ pb: `${100 / ratio}%` }} {...restProps} ref={ref}>
      <StyledContent css={innerCss}>{children}</StyledContent>
    </StyledAspectRatio>
  );
});
