import React from 'react';

import SidePanel, { SidePanelOption, SidePanelProps } from './side-panel';

export interface SinglePanelProps {
  show?: (option: SidePanelOption) => void;
  panelComponent?: (props: SidePanelProps) => JSX.Element;
  panelOption: SidePanelOption;
  width?: number | string;
}

export interface PanelHandler {
  panels?: SinglePanelProps[];
  setPanels: React.Dispatch<
    React.SetStateAction<SinglePanelProps[] | undefined>
  >;
  onAddPanel: (option: SidePanelOption) => void;
  onClosePanel: () => void;
}

interface PanelProps {
  option: SidePanelOption;
  onClose: () => void;
}

function Panel(props: PanelProps, ref: React.Ref<PanelHandler>) {
  return (
    <SidePanel onClose={props.onClose} {...(props.option as SidePanelOption)} />
  );
}

export default React.forwardRef(Panel);
