import * as React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { usePopper } from 'react-popper';
import { CSS, styled } from 'styles';

export interface PopperProps {
  children: (toggle: () => void) => React.ReactNode;
  childrenHasShadow?: boolean;
  className?: string;
  trigger: (
    ref: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
    toggle: () => void,
    isOpen: boolean,
  ) => React.ReactElement;
  css?: CSS;
  childContainerCss?: CSS;
  childContainerClassName?: string;
  outsideClick?: () => void;
}

const Container = styled('div', {
  zIndex: 999,
  borderRadius: 6,
  overflow: 'hidden',
  minWidth: 130,
  padding: 5,
  backgroundColor: '$white',
});

const Outside = styled('div', {});

export function Popper(props: PopperProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    children,
    trigger,
    childrenHasShadow = true,
    className,
    css,
    childContainerClassName,
    childContainerCss,
    outsideClick,
  } = props;

  const [actionElement, setActionElement] = React.useState<HTMLElement | null>(
    null,
  );

  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);

  const { styles: popperStyles, attributes } = usePopper(
    actionElement,
    popperElement,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 3],
          },
        },
      ],
    },
  );

  function toggleOpen() {
    setIsOpen((prev) => !prev);
  }

  return (
    <Outside className={className} css={css}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsOpen(false);
          outsideClick && outsideClick();
        }}
      >
        {trigger(setActionElement, toggleOpen, isOpen)}
        {isOpen && (
          <Container
            ref={setPopperElement}
            style={popperStyles.popper}
            css={{
              boxShadow: childrenHasShadow
                ? '0px 5px 15px -5px hsl(206deg 22% 7% / 15%)'
                : 'none',
              ...childContainerCss,
            }}
            {...attributes.popper}
            className={childContainerClassName}
          >
            {children(toggleOpen)}
          </Container>
        )}
      </OutsideClickHandler>
    </Outside>
  );
}
