import React from 'react';

function Icon(props: any) {
  const { size = 19, color = '#327FEB', ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 19 19"
      {...rest}
    >
      <circle cx="9.664" cy="4.5" r="3.375" stroke={color} strokeWidth="1.25" />
      <path
        stroke={color}
        strokeWidth="1.25"
        d="M2.315 17.875a7.376 7.376 0 0114.698 0H2.315z"
      />
    </svg>
  );
}

export default Icon;
