import React from 'react';

function Icon(props: any) {
  const { size = 64, color = '#104FA6', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        fill={color}
        d="M32 4a22.026 22.026 0 00-22 22 21.778 21.778 0 004.432 13.2s.6.79.698.904L32 60l16.878-19.906c.088-.106.69-.894.69-.894l.002-.006A21.77 21.77 0 0054 26 22.026 22.026 0 0032 4zm0 30a8 8 0 118-8 8.01 8.01 0 01-8 8z"
      />
    </svg>
  );
}

export default Icon;
