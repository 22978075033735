import React from 'react';

function Icon(props: any) {
  const { size = 48, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 48 48"
      {...rest}
    >
      <path fill="#fff" d="M18 8h22.583A3.417 3.417 0 0144 11.417V20H18V8z" />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M4.003 9.5v-.094A3.407 3.407 0 017.413 6h10.032a3.5 3.5 0 012.894 1.53l2.808 4.126a.499.499 0 00.414.22h17.025a3.416 3.416 0 013.417 3.416V38.583A3.42 3.42 0 0140.583 42H7.417a3.414 3.414 0 01-3.414-3.417V9.5z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M24.492 30.292a1.44 1.44 0 01-1.436-1.34l-.496-7.228-.062-1.654a1.996 1.996 0 113.988 0l-.062 1.654-.496 7.227a1.44 1.44 0 01-1.436 1.341zm0 6.804c-.71 0-1.307-.243-1.792-.728-.467-.504-.7-1.12-.7-1.848 0-.747.233-1.363.7-1.848a2.39 2.39 0 011.792-.756c.71 0 1.297.252 1.764.756.485.485.728 1.101.728 1.848 0 .728-.243 1.344-.728 1.848-.467.485-1.055.728-1.764.728z"
      />
    </svg>
  );
}

export default Icon;
