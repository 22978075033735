import React from 'react';

function Icon(props: any) {
  const { size = 23, color = '#327FEB', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size - 4}
      fill="none"
      viewBox="0 0 23 19"
      {...rest}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M9.55 13.969h4.879a.012.012 0 00.012-.01c0-4.27 6.048-4.357 6.295-.252a.274.274 0 00.267.262v0a1.334 1.334 0 001.33-1.408l-.07-1.247a3.205 3.205 0 00-2.258-2.88l-2.502-.774-.349-.71a7.191 7.191 0 00-5.726-3.981H5.711c-.234 0-.464.064-.664.185l-.15.09a6.36 6.36 0 00-2.865 3.85l-.678 4.792c-.155 1.1.7 2.083 1.812 2.083m.07-.01c0 .005.006 0 0 0zm0 0C3.27 9.6 9.35 9.6 9.54 13.691"
      />
      <circle
        cx="17.594"
        cy="14.031"
        r="1.375"
        stroke={color}
        strokeWidth="1.25"
      />
      <circle
        cx="6.375"
        cy="13.922"
        r="1.375"
        stroke={color}
        strokeWidth="1.25"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M9.96 7.344v-2.25h.706c.61 0 1.21.134 1.762.394a5.64 5.64 0 012.21 1.856H9.962zM7.719 7.344v-2.25h-.067c-.504 0-.997.151-1.414.435-.51.346-.92.822-1.188 1.377l-.211.438h2.88z"
      />
    </svg>
  );
}

export default Icon;
