import Text from 'components/commons/text';
import Button from 'components/elements/button';
import * as React from 'react';

import { PanelModal } from './panel-modal';
import { PanelSide } from './primitive-panel';

export interface PNActionProps {
  label: string;
  onAction: (dismiss: VoidFunction) => void;
}

export interface SidePanelOption {
  title?: string;
  children: (onClose: () => void) => React.ReactNode;
  onRenderFooterContent?: React.ReactNode;
  positiveAction?: PNActionProps;
  negativeAction?: PNActionProps;
  width?: string | number;
}

export interface SidePanelProps extends SidePanelOption {
  onClose: () => void;
}

export default function SidePanel(props: SidePanelProps) {
  const {
    width = 600,
    title,
    children,
    onClose,
    onRenderFooterContent,
    positiveAction,
    negativeAction,
  } = props;

  const onPositivePress = React.useCallback(() => {
    if (positiveAction && positiveAction.onAction) {
      positiveAction.onAction(onClose);
    } else {
      onClose();
    }
  }, [onClose, positiveAction]);

  const onNegativePress = React.useCallback(() => {
    if (negativeAction && negativeAction.onAction) {
      negativeAction.onAction(onClose);
    } else {
      onClose();
    }
  }, [onClose, negativeAction]);

  const renderFooter = React.useCallback(() => {
    if (onRenderFooterContent) {
      return onRenderFooterContent;
    }
    if (positiveAction || negativeAction) {
      return (
        <>
          {positiveAction && (
            <Button onClick={onPositivePress} css={{ mr: 8 }}>
              <Text weight="bold">{positiveAction.label}</Text>
            </Button>
          )}
          {negativeAction && (
            <Button onClick={onNegativePress} variant="secondary">
              <Text weight="bold">{negativeAction.label}</Text>
            </Button>
          )}
        </>
      );
    }
    return null;
  }, [
    negativeAction,
    onNegativePress,
    onPositivePress,
    onRenderFooterContent,
    positiveAction,
  ]);

  return (
    <PanelModal onClose={onClose} width={width}>
      <PanelSide
        onClose={onClose}
        title={title}
        onRenderFooterContent={renderFooter()}
        width={width}
        css={{
          maxWidth: width,
          width: '100%',
          left: 0,
          borderRadius: '8px 0px 0px 8px',
          transition: '0.2s',
          overflowY: 'auto',
        }}
      >
        {children(onClose)}
      </PanelSide>
    </PanelModal>
  );
}
