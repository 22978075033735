import React from 'react';

function Icon(props: any) {
  const { size = 36, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size + 1}
      fill="none"
      viewBox="0 0 128 129"
      {...rest}
    >
      <path
        fill="#C9CCCF"
        fillRule="evenodd"
        d="M21.95 25.274a8.064 8.064 0 014.175-1.165h24.152c.09 0 .179.005.268.014a33.02 33.02 0 0126.294 18.281l.972 1.978 9.48 2.93A16.214 16.214 0 0194.9 52.35a16.182 16.182 0 013.786 9.507l.302 5.268a8.26 8.26 0 01-1.858 5.707 8.277 8.277 0 01-6.403 3.03c-2.135 0-3.651-1.728-3.763-3.588-.422-7.03-5.655-10.4-10.98-10.255-2.679.074-5.259 1.055-7.147 2.856-1.85 1.765-3.19 4.46-3.19 8.302a2.69 2.69 0 01-2.69 2.685h-20.61a2.64 2.64 0 01-2.598-3.114 2.647 2.647 0 01-.09-.572c-.324-6.986-5.522-10.422-10.866-10.308-2.684.058-5.282 1.031-7.196 2.85-1.842 1.75-3.204 4.429-3.283 8.259a2.647 2.647 0 01-2.495 2.848 2.654 2.654 0 01-.446.037c-6.3 0-11.15-5.568-10.268-11.807l2.863-20.248c.013-.098.033-.195.058-.291A29.511 29.511 0 0121.32 25.655l.63-.38 1.366 2.258-1.367-2.259zm-3.949 48.194l.289.036c.01-.076.019-.18.02-.306l-.298-.002v.026c0 .083-.003.165-.011.246zm-2.331-2.87v-.05c-.143.001-.278.013-.403.032l.105.001c.1 0 .2.006.298.017zm-2.4-.468c.6-3.844 2.288-6.959 4.689-9.24 2.967-2.82 6.866-4.218 10.72-4.301 7.388-.159 15.058 4.587 16.146 13.992h15.703c.514-3.968 2.207-7.185 4.664-9.528 2.935-2.8 6.808-4.207 10.647-4.313 7.278-.199 14.87 4.346 16.222 13.528.375-.187.711-.452.983-.783l2.042 1.673-2.042-1.673a2.98 2.98 0 00.67-2.059l-.301-5.267a10.9 10.9 0 00-2.551-6.405 10.933 10.933 0 00-5.13-3.396L75.164 49.09a2.64 2.64 0 01-1.59-1.358l-1.474-3A27.74 27.74 0 0050.14 29.39H26.125c-.508 0-1.006.14-1.44.402l-.63.38-1.367-2.258 1.367 2.259a24.23 24.23 0 00-10.88 14.524l-2.842 20.097a5.079 5.079 0 002.937 5.336z"
        clipRule="evenodd"
      />
      <path
        fill="#C9CCCF"
        fillRule="evenodd"
        d="M76.309 76.653a3.169 3.169 0 100-6.337 3.169 3.169 0 000 6.337zm0 5.28a8.45 8.45 0 100-16.898 8.45 8.45 0 000 16.899zM28.945 76.192a3.169 3.169 0 100-6.337 3.169 3.169 0 000 6.337zm0 5.281a8.45 8.45 0 100-16.899 8.45 8.45 0 000 16.899zM41.438 47.872V33.086h5.618c2.963 0 5.888.654 8.569 1.915a26.47 26.47 0 0110.455 8.825l2.818 4.046h-27.46zm16.384-5.28a21.19 21.19 0 00-4.445-2.813 14.852 14.852 0 00-6.32-1.412h-.339v4.225h11.104zM37.23 47.872V33.086h-2.92a13.274 13.274 0 00-7.46 2.294 17.453 17.453 0 00-5.914 6.857l-2.717 5.635H37.23zm-10.368-5.28a12.173 12.173 0 012.955-2.843 7.995 7.995 0 012.133-1.026v3.869h-5.088z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M90.257 69.103c-14.885 0-26.951 12.066-26.951 26.951s12.066 26.952 26.951 26.952 26.952-12.067 26.952-26.952c0-3.572-.694-6.975-1.95-10.087a2.994 2.994 0 115.553-2.242 32.851 32.851 0 012.386 12.33c0 18.192-14.748 32.94-32.94 32.94-18.194 0-32.942-14.748-32.942-32.94 0-18.194 14.748-32.942 32.941-32.942 3.138 0 6.178.44 9.06 1.262a2.994 2.994 0 11-1.645 5.76 26.97 26.97 0 00-7.415-1.032zm14.37.502a2.995 2.995 0 014.161-.788 33.104 33.104 0 016.502 5.825 2.995 2.995 0 11-4.55 3.895 27.097 27.097 0 00-5.325-4.771 2.996 2.996 0 01-.788-4.161z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M103.226 83.455a2.994 2.994 0 010 4.235l-21.337 21.337a2.996 2.996 0 01-4.235-4.235l21.337-21.337a2.995 2.995 0 014.235 0z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M77.66 83.455a2.995 2.995 0 000 4.235l21.337 21.337a2.995 2.995 0 004.235-4.235L81.896 83.455a2.995 2.995 0 00-4.235 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
