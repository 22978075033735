import Text from 'components/commons/text';
import * as React from 'react';
import {
  ToastProvider,
  DefaultToast,
  ToastProps,
} from 'react-toast-notifications';

interface Props {
  children: React.ReactNode;
}

function Toast({ children, ...props }: ToastProps) {
  const content =
    typeof children === 'string' ? <Text>{children}</Text> : children;
  return <DefaultToast {...props}>{content}</DefaultToast>;
}

export default function ToastContainer(props: Props) {
  return <ToastProvider components={{ Toast }}>{props.children}</ToastProvider>;
}
