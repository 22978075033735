import { queryClient } from 'common/repositories/query-client';
// import { signOut } from 'spkf-next-auth';

export default async function logout() {
  // await signOut(
  //   {},
  //   {
  //     baseUrl: '/api/auth',
  //   },
  // );
  await queryClient.invalidateQueries();
}
