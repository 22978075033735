const regex = {
  phone: /^\+[1-9]\d{1,14}$/,
  uuid: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
  password: /^(?=.*\d)(?=\S{8,})\S*$/,
  minLength8: /^.{8,}$/,
  atLeastOneNumber: /^(?=.*\d)/,
  noSpace: /^\S*$/,
  // eslint-disable-next-line no-useless-escape
  atLeastOneSymbolOrNumber: /^(?=.*[\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[^\s]*$/,
  symbolnumber: /[$&+,:;=?@#|'<>.^*()%!-0-9]/,
  space: /\s/,
  atLeastOneLetter: /[A-Za-z]+/,
  dateIsoString:
    /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/,
};

export default regex;
