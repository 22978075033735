import Link from 'next/link';
import { css, styled } from 'styles';
import typography from 'styles/typography';

export const StyledFooter = styled('footer', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$primary',
});

export const StyledFooterContainer = styled('div', {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  px: 16,
  mx: 'auto',
  '@sm': {
    maxWidth: 576,
  },
  '@md': {
    maxWidth: 768,
  },
  '@lg': {
    maxWidth: 992,
  },
  '@xl': {
    maxWidth: 1200,
  },
});

export const StyledContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  py: 32,
  color: '$white',
  '& > *:not(:first-child)': {
    mt: 32,
  },
  '@lg': {
    flexDirection: 'row',
    '& > *:not(:first-child)': {
      mt: 0,
      ml: 32,
    },
  },
});

export const ContentSection = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

export const StyledInformationContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  py: 32,
  color: '$white',
  flexDirection: 'column',
  borderBottom: '1px solid $white',
  '@lg': {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export const StyledLeftSideInformationContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '@lg': {
    maxWidth: 500,
  },
});

export const StyledRightSideInformationContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '& > *:first-child': {
    my: 16,
  },
  '@lg': {
    flexDirection: 'row',
    justifyContent: 'end',
    '& > *:first-child': {
      mr: 16,
      my: 0,
    },
  },
});

export const StyledCopyright = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$primary6',
  padding: 16,
});

export const ALink = styled(Link, {
  ...typography.body2,
  textDecoration: 'none',
  color: '$white',
  '&:hover': {
    opacity: 0.8,
  },
});

export const ImageContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 4,
});

export const ImageContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  border: '1px solid $white',
  borderRadius: 4,
  padding: 0,
  // mr: 8,
});

export const ContactUsItem = styled('div', {
  display: 'flex',
  svg: {
    mr: 16,
  },
});

export const styles = {
  logoBig: css({
    display: 'none',
    '@lg': {
      display: 'initial',
    },
  }),
  logoSmall: css({
    display: 'flex',
    alignItems: 'center',
    '@lg': {
      display: 'none',
    },
  }),
};
