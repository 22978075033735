enum NavigationEnum {
  refund = '/check-booking/[id]/refund',
  reschedule = '/check-booking/[id]/reschedule',
  checkBookingList = '/check-booking',
  guestList = '/guest',
  guestAddPassenger = '/guest/add-passenger',
  guestAddVehicle = '/guest/add-vehicle',
  guestPassengerList = '/guest/[id]/passenger',
  guestVehicleList = '/guest/[id]/vehicle',
  searchShipping = '/shipping/search',
  shippingAddons = '/shipping/addons',
  shippingPayment = '/shipping/[id]/payment',
  shippingDetail = '/shipping/[id]',
  aboutUs = '/about-us',
  forgotPassword = '/forgot-password',
  home = '/',
  login = '/login',
  maintenance = '/maintenance',
  profile = '/profile',
  register = '/register',
  termCondition = '/term-and-condition',
  notFound = '/404',
}

export function getNavigationPathnames() {
  return Object.keys(NavigationEnum).map((key) => {
    return NavigationEnum[key];
  });
}

export default NavigationEnum;
