import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';
import { ApiError, ExtendedApiResult } from 'common/repositories/common.model';

import { getNotificationsInput, NotificationModel } from './model';

export function useGetNotifications(
  options?: UseInfiniteQueryOptions<
    ExtendedApiResult<NotificationModel[]>,
    ApiError,
    ExtendedApiResult<NotificationModel[]>,
    any
  >,
): UseInfiniteQueryResult<ExtendedApiResult<NotificationModel[]>, ApiError> {
  return QueryTransformer(
    useInfiniteQuery(
      ['getNotifications'],
      async ({ pageParam = 1 }) =>
        QueryFetchFunction({
          url: 'notifications',
          params: { page: pageParam },
        }),
      options,
    ),
    NotificationModel,
  );
}
export function getNotificationsKey(input?: getNotificationsInput) {
  return ['getNotifications', input?.params];
}
