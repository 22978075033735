import React from 'react';

function Icon(props: any) {
  const { size = 64, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size + 2}
      fill="none"
      viewBox="0 0 64 66"
      {...rest}
    >
      <path
        fill="#C9CCCF"
        fillRule="evenodd"
        d="M15.44 32.775a8.333 8.333 0 015.893-2.441h13.334A8.333 8.333 0 0143 38.667v3.334a1.667 1.667 0 01-3.333 0v-3.334a5 5 0 00-5-5H21.333a5 5 0 00-5 5v3.334A1.667 1.667 0 0113 42v-3.334c0-2.21.878-4.33 2.44-5.892zM27.997 13.667a5 5 0 100 10 5 5 0 000-10zm-8.333 5a8.333 8.333 0 1116.667 0 8.333 8.333 0 01-16.667 0z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M40.127 35.551c-7.443 0-13.476 6.034-13.476 13.476 0 7.442 6.033 13.476 13.476 13.476 7.442 0 13.475-6.034 13.475-13.476 0-1.786-.346-3.488-.975-5.043a1.497 1.497 0 112.777-1.122 16.427 16.427 0 011.193 6.165c0 9.096-7.374 16.47-16.47 16.47-9.097 0-16.47-7.374-16.47-16.47s7.373-16.47 16.47-16.47c1.568 0 3.088.22 4.53.63a1.497 1.497 0 11-.823 2.88 13.483 13.483 0 00-3.707-.516zm7.185.251a1.497 1.497 0 012.08-.394 16.553 16.553 0 013.251 2.913 1.497 1.497 0 01-2.275 1.947 13.56 13.56 0 00-2.662-2.385 1.497 1.497 0 01-.394-2.08z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M46.607 42.728a1.497 1.497 0 010 2.117L35.94 55.514a1.497 1.497 0 01-2.118-2.118L44.49 42.728a1.497 1.497 0 012.117 0z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M33.822 42.728a1.497 1.497 0 000 2.117l10.669 10.669a1.497 1.497 0 002.117-2.118L35.94 42.728a1.497 1.497 0 00-2.117 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
