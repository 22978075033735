import typography from './typography';

const buttonSize = {
  large: {
    ...typography.buttonMedium,
    height: 48,
    px: 16,
    py: 10,
    svg: {
      width: 24,
      height: 24,
    },
  },
  medium: {
    ...typography.buttonSmall,
    height: 44,
    px: 16,
    py: 12,
    svg: {
      width: 18,
      height: 18,
    },
  },
  small: {
    ...typography.buttonExtraSmall,
    height: 28,
    px: 12,
    py: 6,
    svg: {
      width: 16,
      height: 16,
    },
  },
};

export default buttonSize;
