/* eslint-disable react/no-unknown-property */
import 'reflect-metadata';
import { SSRProvider } from '@react-aria/ssr';
import { QueryClientProvider } from '@tanstack/react-query';
import Config from 'common/config';
import { getLanguage } from 'common/language-storage';
import Layout from 'common/layouts';
import { getNavigationPathnames } from 'common/navigation';
import { queryClient } from 'common/repositories/query-client';
import ToastContainer from 'containers/toast-container';
import { DialogProvider } from 'hooks/use-dialog';
import { PanelProvider } from 'hooks/use-panel';
import yupEnValidation from 'locales/en/validation.yup';
import yupIdValidation from 'locales/id/validation.yup';
import merge from 'lodash/merge';
import RefreshTokenHandler from 'modules/refresh-token-handler';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import appWithI18n from 'next-translate/appWithI18n';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { resetStyles } from 'styles/reset';
import { setLocale } from 'yup';

import 'react-alice-carousel/lib/alice-carousel.css';

import i18nConfig from '../../i18n';

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const { t } = useTranslation();
  const { locale, prefetch, replace, asPath } = useRouter();
  resetStyles();

  React.useEffect(() => {
    if (locale === 'en') {
      setLocale(yupEnValidation as any);
    } else {
      setLocale(yupIdValidation as any);
    }
  }, [locale]);

  React.useEffect(() => {
    async function exec() {
      const pathnames = getNavigationPathnames();
      Promise.all(
        pathnames.map(async (pathname) => {
          return await prefetch(pathname);
        }),
      );
    }
    exec();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    const language = getLanguage() ?? 'id';
    if (locale === language) return;
    replace(asPath, undefined, {
      locale: language,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

  return (
    <>
      <style global jsx>
        {resetStyle}
      </style>
      <DefaultSeo
        defaultTitle={t('common:title')}
        description={t('common:description_web')}
        openGraph={{
          type: 'website',
          locale,
          site_name: 'Wirajaya Line',
          url: 'https://wjl-line.com/',
          description: t('common:description_web'),
        }}
        dangerouslySetAllPagesToNoFollow={
          Config.apiEndpoint !== 'https://www.wjl-line.com'
        }
        dangerouslySetAllPagesToNoIndex={
          Config.apiEndpoint !== 'https://www.wjl-line.com'
        }
      />
      <SSRProvider>
        <QueryClientProvider client={queryClient}>
          {/* <Provider store={store}> */}
          <ToastContainer>
            <DialogProvider>
              <PanelProvider>
                <RefreshTokenHandler />
                {getLayout(<Component {...pageProps} />)}
              </PanelProvider>
            </DialogProvider>
          </ToastContainer>
          {/* </Provider> */}
        </QueryClientProvider>
      </SSRProvider>
    </>
  );
}

export default appWithI18n(MyApp as any, {
  ...i18nConfig,
  loadLocaleFrom: (locale, ns) => {
    const englishTexts = import(`../locales/en/${ns}.json`);
    let countrySpecific = Promise.resolve({});
    let languageOnly = Promise.resolve({});
    if (locale) {
      countrySpecific = import(
        `../locales/${locale.replace('-', '_')}/${ns}.json`
      ).catch(() => ({}));
      languageOnly = import(
        `../locales/${locale.substring(0, 2)}/${ns}.json`
      ).catch(() => ({}));
    }
    return Promise.all([englishTexts, countrySpecific, languageOnly]).then(
      ([en, country, language]) => merge({}, en, country, language),
    );
  },
});

const resetStyle = `
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;
