import React from 'react';

function Icon(props: any) {
  const { size = 80, color = '#654B08', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M31.997 8c-13.254 0-24 10.744-24 24 0 13.254 10.746 24 24 24 13.255 0 24-10.746 24-24 0-3.144-.602-6.14-1.697-8.884a2.667 2.667 0 014.955-1.975 29.262 29.262 0 012.076 10.858c0 16.2-13.133 29.334-29.334 29.334-16.2 0-29.333-13.133-29.333-29.334 0-16.2 13.133-29.333 29.333-29.333 2.529 0 4.986.32 7.332.924a2.667 2.667 0 01-1.33 5.165A24.049 24.049 0 0031.997 8zm12.797.446a2.667 2.667 0 013.705-.701 29.482 29.482 0 015.79 5.187 2.667 2.667 0 11-4.052 3.468 24.149 24.149 0 00-4.741-4.248 2.667 2.667 0 01-.702-3.706z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M31.33 13.33a2.667 2.667 0 012.667 2.667v17.501l7.809 7.206a2.667 2.667 0 01-3.617 3.92l-9.525-8.79V15.997a2.667 2.667 0 012.667-2.667z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
