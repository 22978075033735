import React from 'react';

function Icon(props: any) {
  const { size = 32, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 32 32"
      {...rest}
    >
      <rect width="32" height="32" fill="#BED6F9" fillOpacity="0.2" rx="16" />
      <path
        fill="#104FA6"
        fillRule="evenodd"
        d="M10 17.334c.369 0 .667.299.667.667v2.666a.667.667 0 00.667.667h9.333a.667.667 0 00.667-.667v-2.666a.667.667 0 011.333 0v2.666a2 2 0 01-2 2h-9.333a2 2 0 01-2-2v-2.666c0-.369.298-.667.667-.667z"
        clipRule="evenodd"
      />
      <path
        fill="#104FA6"
        fillRule="evenodd"
        d="M12.195 14.195c.26-.26.683-.26.943 0L16 17.057l2.862-2.862a.667.667 0 11.943.943l-3.334 3.333a.667.667 0 01-.942 0l-3.334-3.333a.667.667 0 010-.943z"
        clipRule="evenodd"
      />
      <path
        fill="#104FA6"
        fillRule="evenodd"
        d="M16 9.334c.369 0 .667.298.667.667v8a.667.667 0 01-1.333 0v-8c0-.369.299-.667.667-.667z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
