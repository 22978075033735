import { styled } from 'styles';

export const FloatingContainer = styled('a', {
  backgroundColor: '#25d366',
  width: 64,
  height: 64,
  position: 'fixed',
  bottom: 24,
  right: 24,
  borderRadius: 9999,
  zIndex: 10,
  boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.2)',
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '0px 0px 4px 1px rgba(0,0,0,0.2)',
    backgroundColor: '#03ff61',
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
