import React from 'react';

function Icon(props: any) {
  const { size = 128, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size + 3}
      fill="none"
      viewBox="0 0 424 427"
      {...rest}
    >
      <path
        fill="#C9CCCF"
        fillRule="evenodd"
        d="M291.335 155.698c-1.37-6.02-6.734-10.292-12.921-10.292H89.438c-7.318 0-13.25 5.919-13.25 13.219v46.266h13.25v-46.266h188.976l9.938 43.662 12.921-2.927-9.938-43.662z"
        clipRule="evenodd"
      />
      <path
        fill="#C9CCCF"
        fillRule="evenodd"
        d="M122.562 92.531c0-7.3-5.932-13.219-13.25-13.219h-6.625c-7.317 0-13.25 5.919-13.25 13.22v66.093h33.125V92.531zm-19.875 0h6.625v52.876h-6.624l-.001-52.876zM162.236 92.515c-.009-7.294-5.938-13.203-13.25-13.203h-6.541c-7.324 0-13.259 5.929-13.25 13.236l.084 66.077h33.041l-.084-66.11zm-19.791.016h6.541l.068 52.876h-6.542l-.067-52.876zM258.64 115.513c-1.564-5.762-6.805-9.763-12.789-9.763h-63.665c-7.318 0-13.25 5.918-13.25 13.219v39.656h101.408l-11.704-43.112zm-76.454 3.456h63.665l7.177 26.437h-70.842v-26.437zM329.372 210.599c4.211-8.774-2.2-18.927-11.951-18.927h-88.402c-3.828 0-7.47 1.652-9.986 4.53l-8.722 9.976h-87.359l-6.455-8.987a13.263 13.263 0 00-10.771-5.519H64.664c-8.535 0-14.844 7.934-12.902 16.226l12.03 51.371 12.902-3.007-12.03-51.371h41.062l6.455 8.987a13.263 13.263 0 0010.771 5.519h87.359c3.829 0 7.47-1.652 9.986-4.53l-4.993-4.344 4.993 4.344 8.722-9.976h88.402l-19.249 40.11 11.951 5.709 19.249-40.111z"
        clipRule="evenodd"
      />
      <path
        fill="#C9CCCF"
        fillRule="evenodd"
        d="M197.071 258.628a39.84 39.84 0 0131.697 0l9.06 3.93a49.294 49.294 0 0041.497-1.057 36.01 36.01 0 0136.165 2.438l8.728 5.906a6.633 6.633 0 009.201-1.759 6.6 6.6 0 00-1.763-9.18L322.929 253a49.285 49.285 0 00-49.5-3.337 36.017 36.017 0 01-30.319.772l-9.06-3.93a53.118 53.118 0 00-42.261 0l-8.738 3.79a39.837 39.837 0 01-31.696 0l-7.433-3.224a53.114 53.114 0 00-44.713 1.139l-3.144 1.558a32.838 32.838 0 01-29.162 0c-16.015-7.938-35.19-5.902-49.171 5.223l-5.301 4.217a6.6 6.6 0 00-1.049 9.289 6.635 6.635 0 009.31 1.046l5.302-4.218a32.833 32.833 0 0135.013-3.719 46.114 46.114 0 0040.953 0l3.144-1.558a39.836 39.836 0 0133.535-.854l7.433 3.224a53.12 53.12 0 0042.262 0l8.737-3.79z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M298.972 228.363c-49.306 0-89.277 39.877-89.277 89.067 0 49.19 39.971 89.067 89.277 89.067 49.306 0 89.277-39.877 89.277-89.067 0-11.805-2.297-23.05-6.46-33.334-2.051-5.067.404-10.835 5.483-12.882 5.08-2.047 10.861.403 12.913 5.47 5.1 12.599 7.903 26.358 7.903 40.746 0 60.121-48.853 108.859-109.116 108.859S189.855 377.551 189.855 317.43c0-60.122 48.854-108.86 109.117-108.86 10.393 0 20.462 1.453 30.008 4.171 5.268 1.501 8.319 6.977 6.815 12.233-1.504 5.256-6.993 8.3-12.261 6.799a89.542 89.542 0 00-24.562-3.41zm47.601 1.659c3.086-4.516 9.257-5.682 13.784-2.604a109.587 109.587 0 0121.536 19.25c3.563 4.152 3.077 10.399-1.084 13.953-4.162 3.555-10.424 3.071-13.987-1.081a89.724 89.724 0 00-17.639-15.767c-4.527-3.078-5.695-9.234-2.61-13.751z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M341.928 275.799a9.879 9.879 0 010 13.995l-70.678 70.512c-3.874 3.864-10.154 3.864-14.028 0a9.88 9.88 0 010-13.996l70.677-70.511c3.874-3.865 10.155-3.865 14.029 0z"
        clipRule="evenodd"
      />
      <path
        fill="#F10C0C"
        fillRule="evenodd"
        d="M257.244 275.799a9.879 9.879 0 000 13.995l70.678 70.512c3.873 3.864 10.154 3.864 14.028 0a9.88 9.88 0 000-13.996l-70.678-70.511c-3.873-3.865-10.154-3.865-14.028 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
