import * as React from 'react';

import { FloatingContainer } from './styles';
interface LinkProps {
  link: string;
  children: React.ReactNode;
}
export default function FloatingButtonLink(props: LinkProps) {
  const { link, children } = props;
  return (
    <FloatingContainer href={link} target="_blank">
      {children}
    </FloatingContainer>
  );
}
