import { ColumnReport } from 'api-hooks/common/model';
import { Type, Expose } from 'class-transformer';

export class ApiError {
  message: string;
  statusCode?: number;
  errors?: { [key: string]: string };
}

export class ApiResult<T> {
  data: T;
  message?: string;
}

export class MessageResult {
  message: string;
}

export class PaginationMeta {
  @Expose({ name: 'current_page' })
  currentPage: number;

  from: number;

  @Expose({ name: 'last_page' })
  lastPage: number;

  path: string;

  @Expose({ name: 'per_page' })
  perPage: number;

  to: number;
  total: number;
}

export class ExtendedApiResult<T> extends ApiResult<T> {
  @Type(() => PaginationMeta)
  meta: PaginationMeta;
  filters: Filter[];
  sorts: Sort;
  columns: ColumnReport[];
}

export enum FilterType {
  Text = 'text',
  Number = 'number',
  Option = 'option',
  Date = 'date',
}

export enum FilterBehaviour {
  Exact = 'exact',
  Partial = 'partial',
  Range = 'range',
  Single = 'single',
  Multiple = 'multiple',
  Before = 'before',
  After = 'after',
}

export class Option {
  name: string;
  value: string;
}

export class Filter {
  name: string;
  label: string;
  type: string;

  @Type(() => Option)
  options?: Option[];

  behaviour: FilterBehaviour;
  value?: string;
  default?: string;
}
export class Sort {
  options: string[];
  default: string;
  value?: string;
}

export class getEnumsInput {
  class: string;
}

export class EnumResult {
  label: string;
  value: string;
}

export enum PhoneTypeEnum {
  mobile,
  office,
  whatsapp,
}

export class ImageClass {
  @Expose({ name: 'created_at' })
  createdAt: string;

  @Expose({ name: 'updated_at' })
  updatedAt: string;

  id: string;
  name: string;
  url: string;
}
